import { formatSelectCustomOptions } from 'utils/selectOptions.utils'
import { formatTicketDatesOptions } from 'utils/tickets.utils'
import { flatArray } from 'utils/helpers.utils'
import { snakeCaseToCamelCase } from 'utils/nameStyle.utils'
import { isObject } from 'utils/validators.utils'
import { formatCurrencySelectOptions } from 'utils/currenciesShared.utils'
import { getCurrencyLabels } from 'constants/currencies.constants'
import { ORDER_BUMPS_VIEW_TYPES_KEYS } from 'constants/orderBumpsShared.constants'
import {
  PRODUCT_TAXONOMOY_CATEGORIES_OPTIONS,
  PRODUCT_TYPE_IDS,
  PRODUCT_TYPE_OPTIONS,
  REVIEW_STATES,
} from './productsShared.constants'
import {
  PAYMENT_FORM_TYPE_OPTIONS,
  TRANSFER_PROVIDERS,
  TRANSFER_PROVIDERS_OPTIONS,
  TRANSFER_PROVIDERS_BASE_OPTIONS,
  TRANSFER_STATE_OPTIONS,
  TRANSFER_STATE_ADMIN_OPTIONS,
  TRANSFERABLE_TYPE,
  PAYMENT_FORM_TYPE_OPTIONS_FOR_NORMAL_SELLER,
  TRANSFER_PROVIDERS_ADMIN_OPTIONS,
  TRANSFER_PROVIDERS_BASE_ADMIN_OPTIONS,
  VIBAN_TRANSFER_STATE_OPTIONS,
  CASHOUT_TRANSFER_STATE_OPTIONS,
} from './transfersShared.constants'
import { AFFILIATE_NODE_STATE_OPTIONS } from './affiliateNodes.constants'
import {
  ACTIVE_PROFILES,
  BANK_ACCOUNT_STATUSES,
  PAYMENT_PROFILE_TYPES,
  PAYMENT_PROFILE_TYPES_VALUES,
  UNACTIVE_PROFILES,
} from './profile.constants'

import { ORDER_RATES_STATES } from './orderRates.constants'
import { ORDER_RATES_STATES_OPTIONS } from './orderRatesStatesOptions.constants'
import {
  EXTERNAL_TRANSFERS_KIND_OPTIONS,
  INTERNAL_STATUS_OPTIONS,
} from '../containers/admin/constants/externalTransfers.constants'
import { EMBED_TYPES, FORM_KEYS, FORM_TRANSLATIONS, PURPOSES_KEYS } from './embeddableItemsShared.constants'
import { PAYMENT_PLANS } from './pricingPlans.constants'
import { LESSON_STATUSES_PUBLISH_STATES } from './courses.constants'
import { ORDER_TYPES, PAYMENT_STATES_LIST } from './ordersShared.constants'
import { TRACKING_CODE_TYPES_LIST, TRACKING_CODE_TYPES_OPTIONS } from './trackingCodes.constants'
import { CUSTOM_TEMPLATE_TYPES } from './optIns.constants'
import { CUSTOM_FIELDS_INTEGRATIONS_LIST, INTEGRATIONS } from './integrationsShared.constants'
import { SHARABLE_TYPE } from './sharing.constants'
import { PAYMENT_TICKETS_STATES } from './paymentTickets.constants'
import {
  BANK_ACCOUNT_INTERNAL_STATE,
  BANK_ACCOUNT_EXTERNAL_STATE,
} from '../containers/admin/constants/bank_accounts.contants'
import { NOTIFIC_TYPES_KEYS } from './notifications.constants'
import { SELLER_LIST_TYPE_OPTIONS, ADMIN_LIST_TYPE_OPTIONS } from './csvLogsShared.constants'
import {
  BANK_ACCOUNT_LEGITIMATION_TYPES,
  LEMONWAY_EXTERNAL_STATUSES,
  LEMONWAY_INTERNAL_STATUSES,
  MANGOPAY_EXTERNAL_STATUSES,
  MANGOPAY_INTERNAL_STATUSES,
  LEGITIMATION_TYPES,
  ELOPAGE_CONNECT_LEGITIMATION_EXTERNAL_STATUSES,
  ELOPAGE_CONNECT_INTERNAL_STATUTES,
} from './paymentSettingShared.constants'
import { MOBILE_APP_NOTIFICATION_REDIRECTION_KEYS } from './mobileApp.constants'
import { DELETION_STATES_OPTIONS } from './sellerDeletion.constants'

export const DATE_RANGE_TYPE = 'dateRange'
export const DATE_RANGE_WITHOUT_TIME_TYPE = 'dateRangeWithoutTime'
export const DATE_TIME_RANGE_TYPE = 'dateTimeRange'
export const DATE_RELATIVE_RANGE_TYPE = 'dateRelativeRange'
export const SELECT_TYPE = 'select'
export const SELECT_TYPE_WITH_MULTIPLE = 'multipleSelect'
export const SELECT_WITH_SEARCH_TYPE = 'selectWithSearch'
export const SELECT_WITH_MULTIPLE_SEARCH_TYPE = 'selectWithMultipleSearch'
export const SELECT_WITH_MULTIPLE_CREATABLE_TYPE = 'selectWithMultipleCreatableType'
export const TEXT_INPUT_TYPE = 'inputText'
export const NUMBER_INPUT_TYPE = 'inputNumber'
export const DATE_INPUT_TYPE = 'inputDate'
export const NUMBER_RANGE_TYPE = 'numberRange'

/* eslint-disable camelcase */
const LESSON_STATUS_PUBLISH_STATE_OPTIONS = [
  {
    value: LESSON_STATUSES_PUBLISH_STATES.notForPublish,
    label: I18n.t('react.cabinet.lesson_status.publish_state.not_for_publish'),
  },
  {
    value: LESSON_STATUSES_PUBLISH_STATES.pending,
    label: I18n.t('react.cabinet.lesson_status.publish_state.pending'),
  },
  {
    value: LESSON_STATUSES_PUBLISH_STATES.published,
    label: I18n.t('react.cabinet.lesson_status.publish_state.published'),
  },
  {
    value: LESSON_STATUSES_PUBLISH_STATES.finished,
    label: I18n.t('react.cabinet.lesson_status.publish_state.finished'),
  },
  {
    value: LESSON_STATUSES_PUBLISH_STATES.viewed,
    label: I18n.t('react.cabinet.lesson_status.publish_state.viewed'),
  },
]

const COURSE_SESSION_PUBLISH_STATE_OPTIONS = [
  {
    value: 'publish_canceled',
    label: I18n.t('react.shared.publish_state.publish_canceled'),
  },
  {
    value: 'published',
    label: I18n.t('react.shared.publish_state.published'),
  },
  {
    value: 'publishing',
    label: I18n.t('react.shared.publish_state.publishing'),
  },
  {
    value: 'publish_paused',
    label: I18n.t('react.shared.publish_state.publish_paused'),
  },
  {
    value: 'publish_pending',
    label: I18n.t('react.shared.publish_state.publish_pending'),
  },
]

const MEMBERSHIP_SESSION_PUBLISH_STATE_OPTIONS = [
  {
    value: 'publish_canceled',
    label: I18n.t('react.shared.publish_state.publish_canceled'),
  },
  {
    value: 'publishing',
    label: I18n.t('react.shared.publish_state.publishing'),
  },
  {
    value: 'publish_paused',
    label: I18n.t('react.shared.publish_state.publish_paused'),
  },
]

const ACCESS_OPTIONS = [
  {
    value: 'false',
    label: I18n.t('react.common.access.active'),
  },
  {
    value: 'true',
    label: I18n.t('react.common.access.blocked'),
  },
]

const LESSON_STATUS_NOTIFY_STATE_OPTIONS = [
  {
    value: 'no_need_email',
    label: I18n.t('react.cabinet.lesson_status.notify_state.no_need_email'),
  },
  {
    value: 'outdate',
    label: I18n.t('react.cabinet.lesson_status.notify_state.outdate'),
  },
  {
    value: 'waiting',
    label: I18n.t('react.cabinet.lesson_status.notify_state.waiting'),
  },
  {
    value: 'notified',
    label: I18n.t('react.cabinet.lesson_status.notify_state.notified'),
  },
]

const PAYMENT_SETTINGS_TYPE_OPTIONS = [
  {
    value: 'Seller',
    label: ACTIVE_PROFILES.seller,
  },
  {
    value: 'Reseller',
    label: UNACTIVE_PROFILES.reseller,
  },
]

const VAT_SETTINGS_OWNERABLE_TYPE = [
  {
    value: 'Seller',
    label: PAYMENT_PROFILE_TYPES.seller,
  },
  {
    value: 'Reseller',
    label: PAYMENT_PROFILE_TYPES.reseller,
  },
]

const PAYOUTS_OWNERABLE_TYPE_OPTIONS = [
  {
    value: 'Publisher',
    label: 'Publisher',
  },
  {
    value: 'Seller',
    label: 'Seller',
  },
  {
    value: 'TeamMember',
    label: 'Team Member',
  },
  {
    value: 'EloPublisher',
    label: 'Elo publisher',
  },
]

const CSV_LOGS_OWNERABLE_OPTIONS = [
  {
    value: ACTIVE_PROFILES.manager,
    label: 'Manager',
  },
  {
    value: ACTIVE_PROFILES.publisher,
    label: 'Publisher',
  },
  {
    value: ACTIVE_PROFILES.seller,
    label: 'Seller',
  },
  {
    value: ACTIVE_PROFILES.teamMember,
    label: 'Team Member',
  },
  {
    value: ACTIVE_PROFILES.salesTeamMember,
    label: 'Sales team member',
  },
  {
    value: ACTIVE_PROFILES.eloPublisher,
    label: 'Elo publisher',
  },
  {
    value: ACTIVE_PROFILES.payer,
    label: 'Payer',
  },
]

const LEMON_WAY_EXT_STATE_OPTIONS = [
  {
    value: LEMONWAY_EXTERNAL_STATUSES.kyc1,
    label: 'lw_kyc_1',
  },
  {
    value: LEMONWAY_EXTERNAL_STATUSES.kyc2,
    label: 'lw_kyc_2',
  },
  {
    value: LEMONWAY_EXTERNAL_STATUSES.closed,
    label: 'lw_closed',
  },
  {
    value: LEMONWAY_EXTERNAL_STATUSES.kycIncomplete,
    label: 'lw_kyc_incomplete',
  },
  {
    value: LEMONWAY_EXTERNAL_STATUSES.rejected,
    label: 'lw_rejected',
  },
  {
    value: LEMONWAY_EXTERNAL_STATUSES.blocked,
    label: 'lw_blocked',
  },
  {
    value: LEMONWAY_EXTERNAL_STATUSES.expired,
    label: 'lw_expired',
  },
]

const MANGOPAY_EXT_STATE_OPTIONS = [
  {
    value: MANGOPAY_EXTERNAL_STATUSES.light,
    label: 'mp_light',
  },
  {
    value: MANGOPAY_EXTERNAL_STATUSES.regular,
    label: 'mp_regular',
  },
]

const ELOPAGE_CONNECT_EXT_STATE_OPTIONS = [
  {
    value: ELOPAGE_CONNECT_LEGITIMATION_EXTERNAL_STATUSES.noDocuments,
    label: 'ec_no_documents',
  },
  {
    value: ELOPAGE_CONNECT_LEGITIMATION_EXTERNAL_STATUSES.verification,
    label: 'ec_verification',
  },
  {
    value: ELOPAGE_CONNECT_LEGITIMATION_EXTERNAL_STATUSES.rejected,
    label: 'ec_rejected',
  },
  {
    value: ELOPAGE_CONNECT_LEGITIMATION_EXTERNAL_STATUSES.payments,
    label: 'ec_payments',
  },
  {
    value: ELOPAGE_CONNECT_LEGITIMATION_EXTERNAL_STATUSES.payouts,
    label: 'ec_payouts',
  },
  {
    value: ELOPAGE_CONNECT_LEGITIMATION_EXTERNAL_STATUSES.paymentsPayouts,
    label: 'ec_payments_payouts',
  },
]

const LEMONWAY_INT_STATE_OPTIONS = [
  {
    value: LEMONWAY_INTERNAL_STATUSES.reviewed,
    label: 'lw_reviewed',
  },
  {
    value: LEMONWAY_INTERNAL_STATUSES.init,
    label: 'lw_init',
  },
]

const MANGOPAY_INT_STATE_OPTIONS = [
  {
    value: MANGOPAY_INTERNAL_STATUSES.headQuarter,
    label: 'mp_head_quarter',
  },
  {
    value: MANGOPAY_INTERNAL_STATUSES.legalRepresentative,
    label: 'mp_legal_representative',
  },
  {
    value: MANGOPAY_INTERNAL_STATUSES.ubo,
    label: 'mp_ubo',
  },
  {
    value: MANGOPAY_INTERNAL_STATUSES.documents,
    label: 'mp_documents',
  },
  {
    value: MANGOPAY_INTERNAL_STATUSES.registered,
    label: 'mp_registered',
  },
  {
    value: MANGOPAY_INTERNAL_STATUSES.legitimated,
    label: 'mp_legitimated',
  },
  {
    value: MANGOPAY_INTERNAL_STATUSES.rejected,
    label: 'mp_rejected',
  },
]

const ELOPAGE_CONNECT_INT_STATE_OPTIONS = [
  {
    value: ELOPAGE_CONNECT_INTERNAL_STATUTES.dataMismatch,
    label: 'ec_data_mismatch',
  },
]

const LEGITIMATION_COMMON_INT_STATE_OPTIONS = [
  {
    value: LEMONWAY_INTERNAL_STATUSES.requested,
    label: 'ec_lw_mp_requested',
  },
  {
    value: LEMONWAY_INTERNAL_STATUSES.change,
    label: 'lw_mp_change',
  },
  {
    value: LEMONWAY_INTERNAL_STATUSES.inReview,
    label: 'lw_mp_in_review',
  },
]

const FINANCIAL_REPORTS_REPORT_STATE_OPTIONS = [
  {
    value: 'for_seller',
    label: 'for seller',
  },
  {
    value: 'for_elopage',
    label: 'for elopage',
  },
]

const MONTHLY_INVOICES_CHARGING_FORM_OPTIONS = [
  {
    value: '0',
    label: 'balance',
  },
  {
    value: '1',
    label: 'external',
  },
]

const MONTHLY_INVOICES_VERSION_OPTIONS = [
  {
    value: 0,
    label: 'v1',
  },
  {
    value: 1,
    label: 'v2',
  },
]

const MONTHLY_INVOICES_STATE_OPTIONS = [
  {
    value: 0,
    label: I18n.t('shared.payment_state.unpaid'),
  },
  {
    value: 1,
    label: I18n.t('shared.payment_state.paid'),
  },
  {
    value: 2,
    label: I18n.t('react.cabinet.payment_methods.lemonway.statuses.on_hold'),
  },
  {
    value: 3,
    label: I18n.t('shared.payment_state.payment_pending'),
  },
  {
    value: 4,
    label: I18n.t('shared.payment_state.refund'),
  },
  {
    value: 5,
    label: I18n.t('shared.payment_state.chargeback'),
  },
  {
    value: 6,
    label: 'issued',
  },
  {
    value: 7,
    label: 'canceled',
  },
]

const MONTHLY_INVOICES_FORM_OPTIONS = [
  {
    value: 'regular',
    label: 'regular',
  },
  {
    value: 'reversal',
    label: 'reversal',
  },
  {
    value: 'corrected',
    label: 'corrected',
  },
]

const THEME_FORMS = [
  {
    value: '0',
    label: 'default',
  },
  {
    value: '1',
    label: 'custom',
  },
  {
    value: '2',
    label: 'namotto',
  },
]

const PRODUCT_REVIEW_STATES = [
  {
    value: REVIEW_STATES.free,
    label: I18n.t('react.cabinet.product.list.review_states.free'),
  },
  {
    value: REVIEW_STATES.reviewed,
    label: I18n.t('react.cabinet.product.list.review_states.reviewed'),
  },
  {
    value: REVIEW_STATES.inReview,
    label: I18n.t('react.cabinet.product.list.review_states.in_review'),
  },
  {
    value: REVIEW_STATES.rejected,
    label: I18n.t('react.cabinet.product.list.review_states.rejected'),
  },
  {
    value: REVIEW_STATES.notForReview,
    label: I18n.t('react.cabinet.product.list.review_states.not_for_review'),
  },
]

export const EMBEDDABLE_ITEMS_FORM_OPTIONS = Object.keys(FORM_KEYS).map((key) => ({
  value: FORM_KEYS[key],
  label: FORM_TRANSLATIONS[key],
}))

const PURPOSES_KEYS_LABELS = {
  shop: I18n.t('react.shared.embeddable_items.purposes.shop'),
  product: I18n.t('react.shared.embeddable_items.purposes.product'),
  courseLesson: I18n.t('react.shared.embeddable_items.purposes.course_lesson'),
  loginPage: I18n.t('react.shared.embeddable_items.purposes.login_page'),
  customLink: I18n.t('react.shared.embeddable_items.purposes.custom_link'),
}
export const EMBEDDABLE_ITEMS_PURPOSE_OPTIONS = Object.keys(PURPOSES_KEYS).map((key) => ({
  value: PURPOSES_KEYS[key],
  label: PURPOSES_KEYS_LABELS[key],
}))
const CUSTOM_TEMPLATE_TYPES_LABELS = {
  checkbox: I18n.t('react.cabinet.opt_in.form.type.options.checkbox'),
  openQuestion: I18n.t('react.cabinet.opt_in.form.type.options.open_question'),
  service: I18n.t('react.cabinet.opt_in.form.type.options.service'),
  selectBox: I18n.t('react.cabinet.opt_in.form.type.options.select_box'),
}
export const CUSTOM_FIELD_TYPE_OPTIONS = Object.values(CUSTOM_TEMPLATE_TYPES).map((value) => ({
  label: CUSTOM_TEMPLATE_TYPES_LABELS[value],
  value,
}))

const CUSTOM_FIELDS_INTEGRATIONS_OPTIONS_LOCALES = {
  active_campaign: I18n.t('react.cabinet.opt_in.form.lb.integrations.active_campaign'),
  get_response: I18n.t('react.cabinet.opt_in.form.lb.integrations.get_response'),
  klick_tipp: I18n.t('react.cabinet.opt_in.form.lb.integrations.klick_tipp'),
  mail_chimp: I18n.t('react.cabinet.opt_in.form.lb.integrations.mail_chimp'),
  mailing_work: I18n.t('react.cabinet.opt_in.form.lb.integrations.mailing_work'),
  zapier: I18n.t('react.cabinet.opt_in.form.lb.integrations.zapier'),
}

export const CUSTOM_FIELDS_INTEGRATIONS_OPTIONS = CUSTOM_FIELDS_INTEGRATIONS_LIST.map((value) => ({
  label: CUSTOM_FIELDS_INTEGRATIONS_OPTIONS_LOCALES[value],
  value,
}))

export const SERVER_KEYS = {
  till: ['till', 'notify_date_till', 'start_till', 'end_till'],
  from: ['from', 'notify_date_from', 'start_from', 'end_from'],
}

export const YES_NO_OPTIONS = [
  {
    value: 'true',
    label: I18n.t('react.common.yes'),
  },
  {
    value: 'false',
    label: I18n.t('react.common.no'),
  },
]

export const YES_OPTION = [
  {
    value: 'true',
    label: I18n.t('react.common.yes'),
  },
]

export const TRANSFER_KIND_OPTIONS = [
  {
    value: 'bank_wire',
    label: I18n.t('shared.payment_form.bank_wire'),
  },
  {
    value: 'chargeback',
    label: I18n.t('shared.payment_state.chargeback'),
  },
]

export const SHARABLE_FORM_OPTIONS = [
  {
    value: 'private',
    label: 'Private',
  },
  {
    value: 'public',
    label: 'Public',
  },
]

export const SHARABLE_TYPE_OPTIONS = [
  {
    value: SHARABLE_TYPE.shop,
    label: 'Shop theme',
  },
  {
    value: SHARABLE_TYPE.membership,
    label: 'Membershipt theme',
  },
  {
    value: SHARABLE_TYPE.product,
    label: 'Product',
  },
]

export const CONTACT_PROVIDER_OPTIONS = [
  {
    value: 'active_campaign',
    label: 'Active campaing',
  },
  {
    value: 'mail_chimp',
    label: 'Mail chimp',
  },
  {
    value: 'get_response',
    label: 'Get re sponse',
  },
  {
    value: 'klick_tipp',
    label: 'Klick tipp',
  },
  {
    value: 'zapier',
    label: 'Zapier',
  },
  {
    value: 'mailing_work',
    label: 'Mailing work',
  },
]

export const PRODUCT_ASSIGNMENT_FORM = [
  {
    value: 'lesson_finish',
    label: 'Lesson finish',
  },
  {
    value: 'quiz_success',
    label: 'Quiz success',
  },
]

export const COUPON_APPLY_FORM = [
  {
    value: 'all_products',
    label: I18n.t('react.cabinet.coupons.form.all_products'),
  },
  {
    value: 'single_product',
    label: I18n.t('react.cabinet.coupons.form.single_product'),
  },
  {
    value: 'multiple_products',
    label: I18n.t('react.cabinet.coupons.form.multiple_products'),
  },
]

export const CREDIBLE_TYPE_OPTIONS = [
  {
    value: 'Refund',
    label: I18n.t('shared.payment_state.refund'),
  },
  {
    value: 'Chargeback',
    label: I18n.t('shared.payment_state.chargeback'),
  },
]

export const CREDIBLE_FORM_OPTIONS = [
  {
    value: 'regular',
    label: I18n.t('react.cabinet.credit_memos.credible_form.regular'),
  },
  {
    value: 'reversal',
    label: I18n.t('react.cabinet.credit_memos.credible_form.reversal'),
  },
  {
    value: 'monthly_payment',
    label: I18n.t('react.cabinet.credit_memos.credible_form.monthly_payment'),
  },
]

export const DOMAIN_STATE_OPTIONS = [
  {
    value: 0,
    label: 'waiting',
  },
  {
    value: 1,
    label: 'active',
  },
  {
    value: 2,
    label: 'error',
  },
  {
    value: 3,
    label: 'active_with_ssl',
  },
]

export const REQUEST_STATE_OPTIONS = [
  {
    value: 0,
    label: 'waiting',
  },
  {
    value: 1,
    label: 'approved',
  },
  {
    value: 2,
    label: 'rejected',
  },
  {
    value: 3,
    label: 'canceled',
  },
]

export const PAYOUT_STATE_OPTIONS = [
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'issued',
    label: 'Issued',
  },
  {
    value: 'successful',
    label: 'Successful',
  },
  {
    value: 'canceled',
    label: 'Canceled',
  },
]

export const CASHOUT_STATE_OPTIONS = [
  {
    value: 'successful',
    label: 'Successful',
  },
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'canceled',
    label: 'Canceled',
  },
]

export const PAYOUT_FORM_OPTIONS = [
  {
    value: 'payout',
    label: 'Payout',
  },
  {
    value: 'cashout',
    label: 'Cashout',
  },
  {
    value: 'reversal',
    label: 'Reversal',
  },
]

export const PAYMENT_PROFILE_TYPES_OPTIONS = PAYMENT_PROFILE_TYPES_VALUES.map((value) => ({
  value,
  label: value,
}))

export const CABINET_PROFILE_TYPES = [
  {
    value: 'Payer',
    label: I18n.t('react.shared.payer'),
  },
  {
    value: 'Publisher',
    label: I18n.t('react.shared.publisher.publisher'),
  },
]

export const BANK_ACCOUNT_STATE_OPTIONS = [
  {
    value: BANK_ACCOUNT_STATUSES.notSent,
    label: BANK_ACCOUNT_STATUSES.notSent,
  },
  {
    value: BANK_ACCOUNT_STATUSES.waiting,
    label: BANK_ACCOUNT_STATUSES.waiting,
  },
  {
    value: BANK_ACCOUNT_STATUSES.activated,
    label: BANK_ACCOUNT_STATUSES.activated,
  },
  {
    value: BANK_ACCOUNT_STATUSES.deactivated,
    label: BANK_ACCOUNT_STATUSES.deactivated,
  },
  {
    value: BANK_ACCOUNT_STATUSES.rejected,
    label: BANK_ACCOUNT_STATUSES.rejected,
  },
  {
    value: BANK_ACCOUNT_STATUSES.initState,
    label: BANK_ACCOUNT_STATUSES.initState,
  },
]

export const BANK_ACCOUNT_INTERNAL_STATE_OPTIONS = [
  {
    value: BANK_ACCOUNT_INTERNAL_STATE.init,
    label: BANK_ACCOUNT_INTERNAL_STATE.init,
  },
  {
    value: BANK_ACCOUNT_INTERNAL_STATE.change,
    label: BANK_ACCOUNT_INTERNAL_STATE.change,
  },
  {
    value: BANK_ACCOUNT_INTERNAL_STATE.accepted,
    label: BANK_ACCOUNT_INTERNAL_STATE.accepted,
  },
  {
    value: BANK_ACCOUNT_INTERNAL_STATE.rejected,
    label: BANK_ACCOUNT_INTERNAL_STATE.rejected,
  },
]

export const BANK_ACCOUNT_EXTERNAL_STATE_OPTIONS = [
  {
    value: BANK_ACCOUNT_EXTERNAL_STATE.notSent,
    label: BANK_ACCOUNT_EXTERNAL_STATE.notSent,
  },
  {
    value: BANK_ACCOUNT_EXTERNAL_STATE.activated,
    label: BANK_ACCOUNT_EXTERNAL_STATE.activated,
  },
  {
    value: BANK_ACCOUNT_EXTERNAL_STATE.deactivated,
    label: BANK_ACCOUNT_EXTERNAL_STATE.deactivated,
  },
  {
    value: BANK_ACCOUNT_EXTERNAL_STATE.waiting,
    label: BANK_ACCOUNT_EXTERNAL_STATE.waiting,
  },
  {
    value: BANK_ACCOUNT_EXTERNAL_STATE.activatedByLw,
    label: BANK_ACCOUNT_EXTERNAL_STATE.activatedByLw,
  },
  {
    value: BANK_ACCOUNT_EXTERNAL_STATE.deactivatedByLw,
    label: BANK_ACCOUNT_EXTERNAL_STATE.deactivatedByLw,
  },
]

export const BANK_ACCOUNT_LEGITIMATION_TYPE_OPTIONS = [
  {
    value: BANK_ACCOUNT_LEGITIMATION_TYPES.mangoPay,
    label: 'MangoPayBankAccount',
  },
  {
    value: BANK_ACCOUNT_LEGITIMATION_TYPES.lemonWay,
    label: 'LemonWayBankAccount',
  },
  {
    value: BANK_ACCOUNT_LEGITIMATION_TYPES.elopageConnect,
    label: 'ElopageConnectBankAccount',
  },
]

export const LEGITIMATION_TYPE_OPTIONS = [
  {
    value: LEGITIMATION_TYPES.mangoPay,
    label: 'MangoPayLegitimation',
  },
  {
    value: LEGITIMATION_TYPES.lemonWay,
    label: 'LemonWayLegitimation',
  },
  {
    value: LEGITIMATION_TYPES.elopageConnect,
    label: 'ElopageConnectLegitimation',
  },
]

export const INVOICE_FORM_OPTIONS = [
  {
    value: 'regular',
    label: I18n.t('shared.invoice_form.regular'),
  },
  {
    value: 'reversal',
    label: I18n.t('shared.invoice_form.reversal'),
  },
  {
    value: 'corrected',
    label: I18n.t('shared.invoice_form.corrected'),
  },
]

export const INVOICE_STATE_OPTIONS = [
  {
    value: 'paid',
    label: I18n.t('shared.payment_state.paid'),
  },
  {
    value: 'unpaid',
    label: I18n.t('shared.payment_state.unpaid'),
  },
  {
    value: 'canceled',
    label: I18n.t('shared.payment_state.canceled'),
  },
  {
    value: 'issued',
    label: I18n.t('shared.payment_state.issued'),
  },
]

export const PAYMENT_FORM_OPTIONS = [
  {
    value: 'card',
    label: I18n.t('shared.payment_form.card'),
  },
  {
    value: 'bank_account',
    label: I18n.t('shared.payment_form.bank_account'),
  },
  {
    value: 'free',
    label: I18n.t('shared.payment_form.free'),
  },
  {
    value: 'paypal',
    label: I18n.t('shared.payment_form.paypal'),
  },
  {
    value: 'sofort',
    label: I18n.t('shared.payment_form.sofort'),
  },
  {
    value: 'bank_wire',
    label: I18n.t('shared.payment_form.bank_wire'),
  },
  {
    value: 'sepa',
    label: I18n.t('shared.payment_form.sepa'),
  },
  {
    value: 'p_24',
    label: I18n.t('shared.payment_form.p_24'),
  },
  {
    value: 'pay_later',
    label: I18n.t('react.common.pay_later'),
  },
  {
    value: 'not_assigned',
    label: I18n.t('shared.payment_form.not_assigned'),
  },
  {
    value: 'ext_balance',
    label: I18n.t('shared.payment_form.ext_balance'),
  },
  {
    value: 'apple_pay',
    label: I18n.t('shared.payment_form.apple_pay'),
  },
  {
    value: 'google_pay',
    label: I18n.t('shared.payment_form.google_pay'),
  },
  {
    value: 'ideal',
    label: I18n.t('shared.payment_form.ideal'),
  },
  {
    value: 'klarna',
    label: I18n.t('shared.payment_form.klarna'),
  },
]

export const STATUS_FILTER_OPTIONS = [
  {
    value: 'waiting',
    label: I18n.t('react.cabinet.statuses.waiting'),
  },
  {
    value: 'failed',
    label: I18n.t('react.cabinet.statuses.failed'),
  },
  {
    value: 'completed',
    label: I18n.t('react.cabinet.statuses.completed'),
  },
]

export const STATUS_TRANSFER_OPTIONS = [
  {
    value: 'waiting',
    label: I18n.t('react.cabinet.statuses.waiting'),
  },
  {
    value: 'recent',
    label: I18n.t('react.cabinet.statuses.recent'),
  },
  {
    value: 'processed',
    label: I18n.t('react.cabinet.logs.filters.options.logs_state.processed'),
  },
]

const NEED_ACTION_TRANSFERS_OPTIONS = [
  {
    label: I18n.t('react.cabinet.statuses.recent'),
    value: 'recent',
  },
  {
    label: I18n.t('react.cabinet.statuses.waiting'),
    value: 'waiting',
  },
  {
    label: I18n.t('react.cabinet.statuses.processed'),
    value: 'processed',
  },
]

export const WEBHOOK_STATUS_FILTER_OPTIONS = [
  {
    value: 'active',
    label: I18n.t('react.shared.webhookEndpoint.state.active'),
  },
  {
    value: 'inactive',
    label: I18n.t('react.shared.webhookEndpoint.state.inactive'),
  },
  {
    value: 'paused',
    label: I18n.t('react.shared.webhookEndpoint.state.paused'),
  },
]

export const CONTRACT_STATE_OPTIONS = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
]

export const TRANSFER_FORM_OPTIONS = [
  {
    value: 'card',
    label: I18n.t('shared.payment_form.card'),
  },
  {
    value: 'bank_account',
    label: I18n.t('shared.payment_form.bank_account'),
  },
  {
    value: 'free',
    label: I18n.t('shared.payment_form.free'),
  },
  {
    value: 'paypal',
    label: I18n.t('shared.payment_form.paypal'),
  },
  {
    value: 'sofort',
    label: I18n.t('shared.payment_form.sofort'),
  },
  {
    value: 'bank_wire',
    label: I18n.t('shared.payment_form.bank_wire'),
  },
  {
    value: 'sepa',
    label: I18n.t('shared.payment_form.sepa'),
  },
  {
    value: 'p_24',
    label: I18n.t('shared.payment_form.p_24'),
  },
  {
    value: 'ext_balance',
    label: I18n.t('shared.payment_form.ext_balance'),
  },
  {
    value: 'apple_pay',
    label: I18n.t('shared.payment_form.apple_pay'),
  },
  {
    value: 'google_pay',
    label: I18n.t('shared.payment_form.google_pay'),
  },
  {
    value: 'ideal',
    label: I18n.t('shared.payment_form.ideal'),
  },
  {
    value: 'klarna',
    label: I18n.t('shared.payment_form.klarna'),
  },
]

export const PAYMENT_METHOD_OPTIONS = [
  {
    value: 'card',
    label: I18n.t('shared.payment_form.card'),
  },
  {
    value: 'paypal',
    label: I18n.t('shared.payment_form.paypal'),
  },
  {
    value: 'sofort',
    label: I18n.t('shared.payment_form.sofort'),
  },
  {
    value: 'bank_wire',
    label: I18n.t('shared.payment_form.bank_wire'),
  },
  {
    value: 'sepa',
    label: I18n.t('shared.payment_form.sepa'),
  },
  {
    value: 'p_24',
    label: I18n.t('shared.payment_form.p_24'),
  },
  {
    value: 'apple_pay',
    label: I18n.t('shared.payment_form.apple_pay'),
  },
  {
    value: 'google_pay',
    label: I18n.t('shared.payment_form.google_pay'),
  },
  {
    value: 'ideal',
    label: I18n.t('shared.payment_form.ideal'),
  },
  {
    value: 'klarna',
    label: I18n.t('shared.payment_form.klarna'),
  },
]

export const PAYMENT_TICKET_STATE_OPTIONS = [
  {
    value: PAYMENT_TICKETS_STATES.used,
    label: I18n.t('react.cabinet.payment_tickets.label.used'),
  },
  {
    value: PAYMENT_TICKETS_STATES.unused,
    label: I18n.t('react.cabinet.payment_tickets.label.unused'),
  },
  {
    value: PAYMENT_TICKETS_STATES.refunded,
    label: I18n.t('react.cabinet.payment_tickets.label.refunded'),
  },
  {
    value: PAYMENT_TICKETS_STATES.expired,
    label: I18n.t('react.cabinet.payment_tickets.label.expired'),
  },
]

const PAYMENT_STATE_OPTIONS_LABELS = {
  payment_waiting: I18n.t('shared.payment_state.payment_waiting'),
  payment_pending: I18n.t('shared.payment_state.payment_pending'),
  paying: I18n.t('shared.payment_state.paying'),
  paid: I18n.t('shared.payment_state.paid'),
  payment_paused: I18n.t('shared.payment_state.payment_paused'),
  payment_canceled: I18n.t('shared.payment_state.payment_canceled'),
  payment_refunded: I18n.t('shared.payment_state.payment_refunded'),
  payment_not_completed: I18n.t('shared.payment_state.payment_not_completed'),
  payment_chargebacked: I18n.t('shared.payment_state.payment_chargebacked'),
}

export const PAYMENT_STATE_OPTIONS = PAYMENT_STATES_LIST.map((value) => ({
  value,
  label: PAYMENT_STATE_OPTIONS_LABELS[value],
}))

export const LOGS_STATE_OPTIONS = [
  {
    value: 'waiting',
    label: I18n.t('react.cabinet.logs.filters.options.logs_state.waiting'),
  },
  {
    value: 'prepared',
    label: I18n.t('react.cabinet.logs.filters.options.logs_state.prepared'),
  },
  {
    value: 'processed',
    label: I18n.t('react.cabinet.logs.filters.options.logs_state.processed'),
  },
  {
    value: 'skipped',
    label: I18n.t('react.cabinet.logs.filters.options.logs_state.skipped'),
  },
  {
    value: 'error',
    label: I18n.t('react.cabinet.logs.filters.options.logs_state.error'),
  },
  {
    value: 'error_old',
    label: I18n.t('react.cabinet.logs.filters.options.logs_state.error_old'),
  },
  {
    value: 'partially_paid',
    label: I18n.t('react.cabinet.logs.filters.options.logs_state.partially_paid'),
  },
  {
    value: 'canceled',
    label: I18n.t('react.cabinet.logs.filters.options.logs_state.canceled'),
  },
  {
    value: 'expired',
    label: I18n.t('react.shared.state.expired'),
  },
  {
    value: 'successful',
    label: I18n.t('react.shared.state.successful'),
  },
  {
    value: 'pending',
    label: I18n.t('react.shared.state.pending'),
  },
  {
    value: 'bounced',
    label: I18n.t('react.shared.state.bounced'),
  },
]

export const PUSH_NOTIFICATION_LOGS_STATE_OPTIONS = [
  {
    value: 'waiting',
    label: I18n.t('react.cabinet.logs.filters.options.logs_state.waiting'),
  },
  {
    value: 'processed',
    label: I18n.t('react.cabinet.logs.filters.options.logs_state.processed'),
  },
  {
    value: 'skipped',
    label: I18n.t('react.cabinet.logs.filters.options.logs_state.skipped'),
  },
  {
    value: 'error',
    label: I18n.t('react.cabinet.logs.filters.options.logs_state.error'),
  },
]

const TRACKING_CODE_FORM_OPTIONS_LABELS = {
  google_analytics: I18n.t('react.cabinet.logs.filters.options.tracking_code_form.google_analytics'),
  google_analytics_4: I18n.t('react.cabinet.logs.filters.options.tracking_code_form.google_analytics_4'),
  google_tag_manager: I18n.t('react.cabinet.logs.filters.options.tracking_code_form.google_tag_manager'),
  facebook_pixel: I18n.t('react.cabinet.logs.filters.options.tracking_code_form.facebook_pixel'),
  custom_tracking_codes: I18n.t('react.cabinet.logs.filters.options.tracking_code_form.custom_tracking_codes'),
  tracify: I18n.t('react.cabinet.logs.filters.options.tracking_code_form.tracify'),
}
export const TRACKING_CODE_FORM_OPTIONS = TRACKING_CODE_TYPES_LIST.map((value) => ({
  value,
  label: TRACKING_CODE_FORM_OPTIONS_LABELS[value],
}))

export const TRACKING_FORM_TYPE_OPTIONS = [
  {
    value: 'tracking_code',
    label: I18n.t('react.cabinet.logs.filters.options.form.tracking_code'),
  },
  {
    value: 'tracking_event',
    label: I18n.t('react.cabinet.logs.filters.options.form.tracking_event'),
  },
]

export const EMBED_TYPE_OPTIONS = [
  {
    value: EMBED_TYPES.onPage,
    label: 'On page',
  },
  {
    value: EMBED_TYPES.modal,
    label: 'Modal',
  },
]

export const PERIOD_ORDER_TYPE_OPTIONS = [
  {
    value: PAYMENT_PLANS.oneTime,
    label: I18n.t('react.cabinet.orders.oneTime'),
  },
  {
    value: PAYMENT_PLANS.subscription,
    label: I18n.t('react.cabinet.orders.subscription'),
  },
  {
    value: PAYMENT_PLANS.limitedSubscription,
    label: I18n.t('react.cabinet.orders.limitedSubscription'),
  },
  {
    value: PAYMENT_PLANS.installment,
    label: I18n.t('react.cabinet.orders.installmentPayment'),
  },
]

export const SHOW_NOTIFICATIONS_OPTIONS = [
  {
    value: 'false',
    label: I18n.t('react.cabinet.notifications.unread'),
  },
  {
    value: 'true',
    label: I18n.t('react.cabinet.notifications.read'),
  },
]

export const NOTIFICABLE_TYPES_OPTIONS = [
  {
    value: '',
    label: I18n.t('react.common.all'),
  },
  {
    value: NOTIFIC_TYPES_KEYS.comment,
    label: I18n.t('react.common.comment'),
  },
  {
    value: NOTIFIC_TYPES_KEYS.quizAttempt,
    label: I18n.t('react.common.quiz_attempt'),
  },
  {
    value: NOTIFIC_TYPES_KEYS.dayIncome,
    label: I18n.t('react.shared.notifications.last_day_revenue'),
  },
  {
    value: NOTIFIC_TYPES_KEYS.weekIncome,
    label: I18n.t('react.shared.notifications.last_week_revenue'),
  },
  {
    value: NOTIFIC_TYPES_KEYS.monthIncome,
    label: I18n.t('react.shared.notifications.last_month_revenue'),
  },
  {
    value: NOTIFIC_TYPES_KEYS.freeTransaction,
    label: I18n.t('react.shared.notifications.free_transaction'),
  },
  {
    value: NOTIFIC_TYPES_KEYS.paidTransaction,
    label: I18n.t('react.shared.notifications.paid_transaction'),
  },
  {
    value: NOTIFIC_TYPES_KEYS.orderCreated,
    label: I18n.t('react.shared.notifications.order_created'),
  },
  {
    value: NOTIFIC_TYPES_KEYS.orderCanceled,
    label: I18n.t('react.shared.notifications.order_canceled'),
  },
]

export const PAYER_NOTIFICABLE_TYPES_OPTIONS = [
  {
    value: '',
    label: I18n.t('react.common.all'),
  },
  {
    value: NOTIFIC_TYPES_KEYS.comment,
    label: I18n.t('react.common.comment'),
  },
  {
    value: NOTIFIC_TYPES_KEYS.quizAttempt,
    label: I18n.t('react.common.quiz_attempt'),
  },
]

export const PUSH_NOTIFICATION_NOTIFICABLE_TYPES_OPTIONS = [
  {
    value: '',
    label: I18n.t('react.common.all'),
  },
  {
    value: NOTIFIC_TYPES_KEYS.comment,
    label: I18n.t('react.common.comment'),
  },
  {
    value: NOTIFIC_TYPES_KEYS.quizAttempt,
    label: I18n.t('react.common.quiz_attempt'),
  },
  {
    value: NOTIFIC_TYPES_KEYS.customNotification,
    label: I18n.t('react.common.custom_notification'),
  },
]

export const CUSTOM_NOTIFICATION_TYPE_OPTIONS = [
  {
    value: MOBILE_APP_NOTIFICATION_REDIRECTION_KEYS.regular,
    label: I18n.t('react.shared.mobile_app.notifications.type.content_page'),
  },
  {
    value: MOBILE_APP_NOTIFICATION_REDIRECTION_KEYS.lesson,
    label: I18n.t('react.shared.mobile_app.notifications.type.lesson_link'),
  },
]

const COMPANY_POSITIONS_KEYS = [
  'founder',
  'c_level',
  'product_manager',
  'marketing_manager',
  'project_manager',
  'agency',
  'freelancer',
  'solopreneur',
  'other',
]
const COMPANY_POSITIONS_LABELS = {
  founder: I18n.t('shared.common.founder'),
  c_level: I18n.t('shared.common.c_level'),
  product_manager: I18n.t('shared.common.product_manager'),
  marketing_manager: I18n.t('shared.common.marketing_manager'),
  project_manager: I18n.t('shared.common.project_manager'),
  agency: I18n.t('shared.common.agency'),
  freelancer: I18n.t('shared.common.freelancer'),
  solopreneur: I18n.t('shared.common.solopreneur'),
  other: I18n.t('shared.common.other'),
}
export const COMPANY_POSITIONS = COMPANY_POSITIONS_KEYS.map((i) => ({
  value: i,
  label: COMPANY_POSITIONS_LABELS[i],
}))

export const EMPLOYEES_NUMBER = [
  {
    value: '1-5',
    label: '1-5',
  },
  {
    value: '6-20',
    label: '6-20',
  },
  {
    value: '21-99',
    label: '21-99',
  },
  {
    value: '100-500',
    label: '100-500',
  },
  {
    value: '500+',
    label: '500+',
  },
]

export const BLACK_LIST_FILTER_PARAMS = ['page', 'per', 'query', 'sortDir', 'sortKey']

const API_CONNECTIONS_FORM_OPTIONS = [
  {
    value: INTEGRATIONS.activeCampaign,
    label: I18n.t('react.cabinet.opt_in.form.lb.integrations.active_campaign'),
  },
  {
    value: INTEGRATIONS.mailChimp,
    label: I18n.t('react.cabinet.opt_in.form.lb.integrations.mail_chimp'),
  },
  {
    value: INTEGRATIONS.getResponse,
    label: I18n.t('react.cabinet.opt_in.form.lb.integrations.get_response'),
  },
  {
    value: INTEGRATIONS.mailingWork,
    label: I18n.t('react.cabinet.opt_in.form.lb.integrations.mailing_work'),
  },
  {
    value: INTEGRATIONS.klickTipp,
    label: I18n.t('react.cabinet.opt_in.form.lb.integrations.klick_tipp'),
  },
]

const WEBHOOK_PURPOSE_OPTIONS = [
  {
    value: 'webhook',
    label: 'webhook',
  },
  {
    value: 'integration',
    label: 'integration',
  },
  {
    value: 'common',
    label: 'common',
  },
]

const CONTACT_FORM_TYPE_OPTIONS = [
  {
    value: 'true',
    label: I18n.t('react.cabinet.contact_form_leads.existing'),
  },
  {
    value: 'false',
    label: I18n.t('react.cabinet.contact_form_leads.new'),
  },
]

const ORDER_TYPES_OPTIONS = [
  {
    value: ORDER_TYPES.standard,
    label: I18n.t('react.cabinet.orders.order_type_standard'),
  },
  {
    value: ORDER_TYPES.bundle,
    label: I18n.t('react.cabinet.orders.order_type_bundle'),
  },
  {
    value: ORDER_TYPES.bump,
    label: I18n.t('react.cabinet.orders.order_type_bump'),
  },
]

const ORDER_BUMPS_VIEW_TYPES_OPTIONS = [
  {
    value: ORDER_BUMPS_VIEW_TYPES_KEYS.first,
    label: I18n.t('react.cabinet.order_bumps.view.first'),
  },
  {
    value: ORDER_BUMPS_VIEW_TYPES_KEYS.sec,
    label: I18n.t('react.cabinet.order_bumps.view.sec'),
  },
  {
    value: ORDER_BUMPS_VIEW_TYPES_KEYS.third,
    label: I18n.t('react.cabinet.order_bumps.view.third'),
  },
  {
    value: ORDER_BUMPS_VIEW_TYPES_KEYS.fourth,
    label: I18n.t('react.cabinet.order_bumps.view.fourth'),
  },
  {
    value: ORDER_BUMPS_VIEW_TYPES_KEYS.fifth,
    label: I18n.t('react.cabinet.order_bumps.view.fifth'),
  },
  {
    value: ORDER_BUMPS_VIEW_TYPES_KEYS.six,
    label: I18n.t('react.cabinet.order_bumps.view.six'),
  },
  {
    value: ORDER_BUMPS_VIEW_TYPES_KEYS.seven,
    label: I18n.t('react.cabinet.order_bumps.view.seven'),
  },
]

export const CREATED_RELATIVE_PERIOD_KEYS = {
  last: 'last',
  current: 'current',
  previous: 'previous',
}

export const CREATED_RELATIVE_PERIOD_OPTIONS = [
  {
    label: I18n.t('react.common.last'),
    value: 'last',
  },
  {
    label: I18n.t('react.common.current'),
    value: 'current',
  },
  {
    label: I18n.t('react.common.previous'),
    value: 'previous',
  },
]

export const CREATED_RELATIVE_DAY_TIME_OPTIONS = [
  {
    label: I18n.t('react.common.7_days'),
    value: '7_days',
  },
  {
    label: I18n.t('react.common.30_days'),
    value: '30_days',
  },
  {
    label: I18n.t('react.common.90_days'),
    value: '90_days',
  },
  {
    label: I18n.t('react.common.180_days'),
    value: '180_days',
  },
  {
    label: I18n.t('react.common.365_days'),
    value: '365_days',
  },
]

export const CREATED_RELATIVE_RANGE_OPTIONS = [
  {
    label: I18n.t('react.shared.week'),
    value: 'isoWeek',
  },
  {
    label: I18n.t('react.common.calendar_month'),
    value: 'month',
  },
  {
    label: I18n.t('react.shared.year'),
    value: 'year',
  },
]

export const CREATED_RELATIVE_DEFAULT_TIME = '90_days'

const SUBS_CHARGES_KIND_OPTIONS = [
  {
    label: 'regular',
    value: 'regular',
  },
  {
    label: 'items/videos',
    value: 'items',
  },
  {
    label: 'streams/ls',
    value: 'streams',
  },
]

const filterFormatters = {
  firstLastName: (list) =>
    formatSelectCustomOptions(list, 'id', [
      'id',
      {
        userProfile: ['firstName', 'lastName'],
      },
    ] as string[]),
  firstLastNameEmail: (list) =>
    formatSelectCustomOptions(list, 'id', [
      'id',
      {
        user: ['email'],
        userProfile: ['firstName', 'lastName'],
      },
    ] as string[]),
}

export const RECEIVER_TYPES = {
  seller: 'Seller',
  publisher: 'Publisher',
  eloPublisher: 'EloPublisher',
  teamMember: 'TeamMember',
  payer: 'Payer',
  user: 'User',
  reseller: 'Reseller',
}

export const RECEIVER_TYPES_OPTIONS = [
  {
    value: RECEIVER_TYPES.seller,
    label: I18n.t('react.shared.seller'),
  },
  {
    value: RECEIVER_TYPES.publisher,
    label: I18n.t('react.shared.publisher.publisher'),
  },
  {
    value: RECEIVER_TYPES.eloPublisher,
    label: I18n.t('react.shared.elo_publisher'),
  },
  {
    value: RECEIVER_TYPES.teamMember,
    label: I18n.t('react.shared.team_member'),
  },
  {
    value: RECEIVER_TYPES.payer,
    label: I18n.t('react.shared.payer'),
  },
  {
    value: RECEIVER_TYPES.user,
    label: I18n.t('react.cabinet.common.user'),
  },
]

const DOCUMENT_TYPES = [
  {
    value: 'imprint',
    label: 'Imprint',
  },
  {
    value: 'privacy',
    label: 'Privacy',
  },
  {
    value: 'terms',
    label: 'Terms',
  },
  {
    value: 'vat_exempt',
    label: 'Vat exempt',
  },
  {
    value: 'iban_scan',
    label: 'Iban scan',
  },
  {
    value: 'article_doc',
    label: 'Article doc',
  },
  {
    value: 'company_lr',
    label: 'Company lr',
  },
  {
    value: 'terms_of_business',
    label: 'Terms of business',
  },
  {
    value: 'proof_doc',
    label: 'Proof doc',
  },
  {
    value: 'id_from_front',
    label: 'Id from front',
  },
  {
    value: 'id_from_back',
    label: 'Id from back',
  },
  {
    value: 'other_doc_1',
    label: 'Other doc 1',
  },
  {
    value: 'other_doc_2',
    label: 'Other doc 2',
  },
  {
    value: 'id_verification',
    label: 'Id verification',
  },
  {
    value: 'shareholder_declaration',
    label: 'Shareholder declaration',
  },
]

export const DUNNING_STATUS_TYPE_OPTIONS = [
  {
    value: 'in_progress',
    label: I18n.t('react.cabinet.dunning.status.in_progress'),
  },
  {
    value: 'completed',
    label: I18n.t('react.cabinet.dunning.status.completed'),
  },
  {
    value: 'skipped',
    label: I18n.t('react.cabinet.dunning.status.skipped'),
  },
]

export const PAYER_TYPE_OPTIONS = [
  {
    value: 'false',
    label: I18n.t('react.cabinet.dunning.table.person'),
  },
  {
    value: 'true',
    label: I18n.t('react.cabinet.dunning.table.bussines'),
  },
]

export const DUNNING_STRATEGY_TYPE_OPTIONS = [
  {
    value: 'dunning',
    label: I18n.t('react.cabinet.dunning.settings.claim_strategy.dunning'),
  },
  {
    value: 'dunning_inkasso',
    label: I18n.t('react.cabinet.dunning.settings.claim_strategy.dunning_incasso'),
  },
  {
    value: 'inkasso',
    label: I18n.t('react.cabinet.dunning.settings.claim_strategy.inkasso'),
  },
]

const getInkassoTypes = (type) => {
  const common = [
    {
      value: 'not_ready_for_inkasso',
      label: I18n.t('react.cabinet.dunning.status.not_ready_for_inkasso'),
    },
    {
      value: 'ready_for_inkasso',
      label: I18n.t('react.cabinet.dunning.status.ready_for_inkasso'),
    },
    {
      value: 'in_inkasso',
      label: I18n.t('react.cabinet.dunning.status.in_inkasso'),
    },
    {
      value: 'pre_court',
      label: I18n.t('react.cabinet.dunning.status.pre_court'),
    },
  ]

  if (type === 'admin' || type === 'powerSeller') {
    return [
      ...common,
      {
        value: 'court',
        label: I18n.t('react.cabinet.dunning.status.court'),
      },
      {
        value: 'post_court',
        label: I18n.t('react.cabinet.dunning.status.post_court'),
      },
      {
        value: 'no',
        label: I18n.t('react.cabinet.dunning.status.no'),
      },
    ]
  }
  return [
    ...common,
    {
      value: 'no',
      label: I18n.t('react.cabinet.dunning.status.no'),
    },
  ]
}

export const INKASSO_STATUS_TYPE_OPTIONS_ADMIN = getInkassoTypes('admin')

export const INKASSO_STATUS_TYPE_OPTIONS_SELLER = getInkassoTypes('seller')

export const STRATEGY_STATUS_TYPE_OPTIONS = [
  {
    value: 'collected',
    label: I18n.t('react.cabinet.dunning.status.collected'),
  },
  {
    value: 'not_collected',
    label: I18n.t('react.cabinet.dunning.status.not_collected'),
  },
  {
    value: 'manually_resolved_dunning',
    label: I18n.t('react.cabinet.dunning.status.manually_resolved_dunning'),
  },
  {
    value: 'manually_resolved_inkasso',
    label: I18n.t('react.cabinet.dunning.status.manually_resolved_inkasso'),
  },
  {
    value: 'partially_collected',
    label: I18n.t('react.cabinet.dunning.status.partially_collected'),
  },
]

export const OUTCOME_TYPE_OPTIONS = [
  {
    value: 'paid',
    label: I18n.t('shared.payment_state.paid'),
  },
  {
    value: 'unpaid',
    label: I18n.t('shared.payment_state.unpaid'),
  },
]

export const PRICING_PLAN_FORM_TYPE_OPTIONS = [
  {
    value: '0',
    label: I18n.t('react.cabinet.orders.oneTime'),
  },
  {
    value: '1',
    label: I18n.t('react.cabinet.orders.subscription'),
  },
  {
    value: '2',
    label: I18n.t('react.cabinet.orders.limitedSubscription'),
  },
  {
    value: '3',
    label: I18n.t('react.cabinet.orders.installmentPayment'),
  },
]

export const getStrategyStageOptions = (userType) => {
  const dunningStages = [
    {
      value: 'dunning-first_email',
      label: I18n.t('react.cabinet.dunning.status.stage_1', { count: 1 }),
    },
    {
      value: 'dunning-second_email',
      label: I18n.t('react.cabinet.dunning.status.stage_2', { count: 2 }),
    },
    {
      value: 'dunning-third_email',
      label: I18n.t('react.cabinet.dunning.status.stage_3', { count: 3 }),
    },
  ]

  const inkassoStagesPart = [
    {
      value: 'inkasso-ready_for_inkasso',
      label: I18n.t('react.cabinet.dunning.status.stage_ready_for_inkasso'),
    },
    {
      value: 'inkasso-sent_to_dca',
      label: I18n.t('react.cabinet.dunning.status.stage_in_inkasso'),
    },
    {
      value: 'inkasso-pre_court',
      label: I18n.t('react.cabinet.dunning.status.stage_pre_court'),
    },
  ]

  const inkassoStagesAll = [
    ...inkassoStagesPart,
    {
      value: 'inkasso-court',
      label: I18n.t('react.cabinet.dunning.status.stage_court'),
    },
    {
      value: 'inkasso-post_court',
      label: I18n.t('react.cabinet.dunning.status.stage_post_court'),
    },
  ]

  if (userType === 'admin') {
    return [...inkassoStagesAll, ...dunningStages]
  }

  return [...inkassoStagesPart, ...dunningStages]
}

const PAYMENT_AUTHORIZATION_STATUSES = [
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Authorized',
    value: 'authorized',
  },
  {
    label: 'Successful',
    value: 'successful',
  },
  {
    label: 'Canceled',
    value: 'canceled',
  },
  {
    label: 'Expired',
    value: 'expired',
  },
  {
    label: 'Error',
    value: 'error',
  },
]

const PAYMENT_AUTHORIZATION_PAYMENT_METHODS = [
  {
    label: 'Card',
    value: 'card',
  },
  {
    label: 'Apple pay',
    value: 'apple_pay',
  },
  {
    label: 'Google pay',
    value: 'google_pay',
  },
]

const BOOKING_TYPE_OPTIONS = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Reversed pay',
    value: 'reversed',
  },
  {
    label: 'Created to reverse',
    value: 'created_to_reverse',
  },
  {
    label: 'Refund',
    value: 'refund',
  },
]

const EVENT_KEY_OPTIONS = [
  {
    label: 'Payment successful',
    value: 'payment.successful',
  },
  {
    label: 'Refund successful',
    value: 'refund.successful',
  },
  {
    label: 'Order rate amount changed',
    value: 'order_rate.amount_changed',
  },
  {
    label: 'Order rate discarded',
    value: 'order_rate.discarded',
  },
  {
    label: 'Order created',
    value: 'order.created',
  },
]

const DATEV_STATE_OPTIONS = [
  {
    label: 'Ready to be sent',
    value: 'ready_to_be_sent',
  },
  {
    label: 'Sent to finance',
    value: 'sent_to_finance',
  },
  {
    label: 'Sent to datev',
    value: 'sent_to_datev',
  },
]

const PAYMENT_AUTHORIZATION_PROVIDERS = [
  {
    label: 'Stripe',
    value: 'stripe',
  },
  {
    label: 'Elopage connect',
    value: 'elopage_connect',
  },
  {
    label: 'Mango pay',
    value: 'mango_pay',
  },
]

export const FILTERS_LIST = [
  {
    key: 'documentType',
    serverKey: 'doc_type',
    label: 'Document type',
    type: SELECT_TYPE,
    options: DOCUMENT_TYPES,
    value: DOCUMENT_TYPES[0].value,
  },
  {
    key: 'productType',
    serverKey: 'form',
    label: I18n.t('react.cabinet.product.type'),
    type: SELECT_TYPE,
    options: PRODUCT_TYPE_OPTIONS,
    value: PRODUCT_TYPE_OPTIONS[0].value,
  },
  {
    key: 'sellableProductType',
    serverKey: 'product_form',
    label: I18n.t('react.cabinet.product.type'),
    type: SELECT_TYPE,
    options: PRODUCT_TYPE_OPTIONS,
    value: PRODUCT_TYPE_OPTIONS[0].value,
  },
  {
    key: 'companyPosition',
    serverKey: 'position_in_company',
    label: 'Company position',
    type: SELECT_TYPE,
    options: COMPANY_POSITIONS,
    value: COMPANY_POSITIONS[0].value,
  },
  {
    key: 'employees',
    serverKey: 'employees',
    label: 'Employess',
    type: SELECT_TYPE,
    options: EMPLOYEES_NUMBER,
    value: EMPLOYEES_NUMBER[0].value,
  },
  {
    key: 'customNotificationType',
    serverKey: 'redirection_type',
    label: I18n.t('react.shared.type'),
    type: SELECT_TYPE,
    options: CUSTOM_NOTIFICATION_TYPE_OPTIONS,
  },
  {
    key: 'paymentProfile',
    serverKey: 'profile_type',
    label: 'Profile type',
    type: SELECT_TYPE,
    options: PAYMENT_SETTINGS_TYPE_OPTIONS,
  },
  {
    key: 'profileId',
    serverKey: 'profile_id',
    label: 'Profile id',
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'extId',
    serverKey: 'ext_id',
    label: 'Ext id',
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'exportableId',
    serverKey: 'exportable_id',
    label: 'Exportable id',
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'idForSeller',
    serverKey: 'id_for_seller',
    label: 'Id for seller',
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'invoiceNumber',
    serverKey: 'number',
    label: 'Invoice number',
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'referenceId',
    serverKey: 'bank_wire_ref',
    label: 'MP reference id',
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'userProfileId',
    serverKey: 'user_profile_id',
    label: 'User profile id',
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'legitimationIntState',
    serverKey: 'internal_state',
    label: 'Int state',
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: [
      ...LEGITIMATION_COMMON_INT_STATE_OPTIONS,
      ...LEMONWAY_INT_STATE_OPTIONS,
      ...MANGOPAY_INT_STATE_OPTIONS,
      ...ELOPAGE_CONNECT_INT_STATE_OPTIONS,
    ],
    value: [],
  },
  {
    key: 'legitimationExtState',
    serverKey: 'ext_state',
    label: 'Ext state',
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: [...LEMON_WAY_EXT_STATE_OPTIONS, ...MANGOPAY_EXT_STATE_OPTIONS, ...ELOPAGE_CONNECT_EXT_STATE_OPTIONS],
    value: [],
  },
  {
    key: 'legitimationType',
    serverKey: 'type',
    label: 'Type',
    type: SELECT_TYPE,
    options: LEGITIMATION_TYPE_OPTIONS,
  },
  {
    key: 'requestState',
    serverKey: 'state',
    label: 'Request state',
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: REQUEST_STATE_OPTIONS,
  },
  {
    key: 'payoutState',
    serverKey: 'state',
    label: 'State',
    type: SELECT_TYPE,
    options: PAYOUT_STATE_OPTIONS,
  },
  {
    key: 'cashoutState',
    serverKey: 'state',
    label: 'State',
    type: SELECT_TYPE,
    options: CASHOUT_STATE_OPTIONS,
  },
  {
    key: 'payoutForm',
    serverKey: 'form',
    label: 'Form(Payout/Cashout)',
    type: SELECT_TYPE,
    options: PAYOUT_FORM_OPTIONS,
  },
  {
    key: 'nationality',
    serverKey: 'nationality',
    label: 'Nationality',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'countriesStore',
    searchLocally: true,
  },
  {
    key: 'emailTemplate',
    serverKey: 'email_template_id',
    label: 'Email Template',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'emailTemplatesStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id', snakeCaseToCamelCase(`name_${I18n.locale}`)]),
  },
  {
    key: 'email',
    serverKey: 'email',
    label: 'Email',
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'emailTo',
    serverKey: 'email_to',
    label: I18n.t('react.shared.email_to'),
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'emailFrom',
    serverKey: 'email_from',
    label: I18n.t('react.shared.email_from'),
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'id',
    serverKey: 'id',
    label: 'Id',
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'claimId',
    serverKey: 'id',
    label: I18n.t('react.cabinet.dunning.table.claim_id'),
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'isPublished',
    serverKey: 'active',
    label: I18n.t('react.cabinet.product.filter.published'),
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'addedToAppStore',
    serverKey: 'for_app_store',
    label: I18n.t('react.shared.mobile_app.products.added_to_the_app_store'),
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'addedToPlayMarket',
    serverKey: 'for_play_market',
    label: I18n.t('react.shared.mobile_app.products.added_to_the_playmarket'),
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'published',
    serverKey: 'publish',
    label: I18n.t('react.cabinet.product.filter.published'),
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'isArchived',
    serverKey: 'archived',
    label: I18n.t('react.cabinet.product.archived'),
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'isPrivate',
    serverKey: 'private',
    label: I18n.t('react.cabinet.product.form.lb.private'),
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'isFree',
    serverKey: 'free',
    label: I18n.t('shared.payment_form.free'),
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'invoiceCreatedDate',
    serverKey: {
      key: 'invoice_created',
      from_time: 'invoice_created_from_time',
      till_time: 'invoice_created_till_time',
      invoice_created_from_time: 'invoice_created_from_time',
      invoice_created_till_time: 'invoice_created_till_time',
    },
    label: I18n.t('react.shared.invoice_date_range'),
    type: DATE_TIME_RANGE_TYPE,
    placeholder: I18n.t('react.shared.invoice_date_range'),
    value: {},
  },
  {
    key: 'orderRatesState',
    serverKey: 'state',
    label: I18n.t('react.common.state'),
    type: SELECT_TYPE,
    options: ORDER_RATES_STATES_OPTIONS,
    value: ORDER_RATES_STATES.debt,
  },
  {
    key: 'multiOrderRatesState',
    serverKey: 'state',
    label: I18n.t('react.common.state'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: ORDER_RATES_STATES_OPTIONS.filter((option) => option.value !== ORDER_RATES_STATES.skipped),
    value: ORDER_RATES_STATES.debt,
  },
  {
    key: 'isActive',
    serverKey: 'active',
    label: I18n.t('shared.common.active'),
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'withWallet',
    serverKey: 'with_lw_wallet',
    label: 'With wallet',
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'withFile',
    serverKey: 'with_file',
    label: 'With file',
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'withLegitimations',
    serverKey: 'with_legitimations',
    label: 'With legitimations',
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'domainState',
    serverKey: 'domain_state',
    label: 'Domain state',
    type: SELECT_TYPE,
    options: DOMAIN_STATE_OPTIONS,
  },
  {
    key: 'onPricingPage',
    serverKey: 'on_pricing_page',
    label: 'On Pricing Page',
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'applyForm',
    serverKey: 'apply_form',
    label: I18n.t('react.cabinet.common.product'),
    type: SELECT_TYPE,
    options: COUPON_APPLY_FORM,
    value: COUPON_APPLY_FORM[0].value,
  },
  {
    key: 'isRecurring',
    serverKey: 'recurring',
    label: I18n.t('react.cabinet.common.recurring'),
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'creditMemoType',
    serverKey: 'type',
    label: I18n.t('react.cabinet.credit_memos.type'),
    type: SELECT_TYPE,
    options: CREDIBLE_TYPE_OPTIONS,
    value: CREDIBLE_TYPE_OPTIONS[0].value,
  },
  {
    key: 'creditMemoForm',
    serverKey: 'form',
    label: I18n.t('react.cabinet.credit_memos.form'),
    type: SELECT_TYPE,
    options: CREDIBLE_FORM_OPTIONS,
    value: CREDIBLE_FORM_OPTIONS[0].value,
  },
  {
    key: 'senderId',
    serverKey: 'sender_id',
    label: 'Sender(seller)',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'sellersStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id', 'username']),
  },
  {
    key: 'resellerId',
    serverKey: 'reseller_id',
    label: 'Reseller ID',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'resellersStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id', 'username']),
  },
  {
    key: 'senderIdEmailLog',
    serverKey: 'sender_id',
    label: 'Sender(seller)',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'sellersStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id', 'username']),
  },
  {
    key: 'receiverPayer',
    serverKey: 'payer_receiver_id',
    label: 'Receiver payer',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'payersStore',
    boundedKeyValues: {
      receiver_type: RECEIVER_TYPES.payer,
    },
    formatOptions: (list) => filterFormatters.firstLastName(list),
  },
  {
    key: 'receiverTeamMember',
    serverKey: 'team_member_receiver_id',
    label: 'Receiver team member',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'teamMembersStore',
    boundedKeyValues: {
      receiver_type: RECEIVER_TYPES.teamMember,
    },
    formatOptions: (list) => filterFormatters.firstLastName(list),
  },
  {
    key: 'receiverSeller',
    serverKey: 'seller_receiver_id',
    label: 'Receiver seller',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'sellersStore',
    boundedKeyValues: {
      receiver_type: RECEIVER_TYPES.seller,
    },
    formatOptions: (list) => filterFormatters.firstLastName(list),
  },
  {
    key: 'receiverReseller',
    serverKey: 'reseller_receiver_id',
    label: 'Receiver reseller',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'resellersStore',
    boundedKeyValues: {
      receiver_type: RECEIVER_TYPES.reseller,
    },
    formatOptions: (list) => filterFormatters.firstLastName(list),
  },
  {
    key: 'receiverPublisher',
    serverKey: 'publisher_receiver_id',
    label: 'Receiver publisher',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'publishersStore',
    boundedKeyValues: {
      receiver_type: RECEIVER_TYPES.publisher,
    },
    formatOptions: (list) => filterFormatters.firstLastName(list),
  },
  {
    key: 'receiverEloPublisher',
    serverKey: 'elo_publisher_receiver_id',
    label: 'Receiver eloPublisher',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'eloPublishersStore',
    boundedKeyValues: {
      receiver_type: RECEIVER_TYPES.eloPublisher,
    },
    formatOptions: (list) => filterFormatters.firstLastName(list),
  },
  {
    key: 'receiverPayerCabinet',
    serverKey: 'payer_receiver_id',
    label: I18n.t('react.common.filters.receiver_payer'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'payersStore',
    formatOptions: (list) =>
      formatSelectCustomOptions(list, 'id', ['id', { userProfile: ['firstName', 'lastName'] }] as string[]),
  },
  {
    key: 'receiverPublisherCabinet',
    serverKey: 'publisher_receiver_id',
    label: I18n.t('react.common.filters.receiver_publisher'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'publishersStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id', 'fullName']),
  },
  {
    key: 'receiverUser',
    serverKey: 'user_receiver_id',
    label: I18n.t('react.common.filters.receiver_id'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'usersStore',
    boundedKeyValues: {
      receiver_type: RECEIVER_TYPES.user,
    },
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id', 'email']),
  },
  {
    key: 'receiverType',
    serverKey: 'receiver_type',
    label: 'Receiver type',
    type: SELECT_TYPE,
    options: PAYMENT_PROFILE_TYPES_OPTIONS,
    value: PAYMENT_PROFILE_TYPES_OPTIONS[0].value,
  },
  {
    key: 'receiverTypeCabinet',
    serverKey: 'receiver_type',
    label: I18n.t('react.common.filters.receiver_type'),
    type: SELECT_TYPE,
    options: CABINET_PROFILE_TYPES,
    value: CABINET_PROFILE_TYPES[0].value,
  },
  {
    key: 'receiverTypeLog',
    serverKey: 'receiver_type',
    label: 'Receiver type',
    type: SELECT_TYPE,
    options: RECEIVER_TYPES_OPTIONS,
    value: RECEIVER_TYPES_OPTIONS[0].value,
  },
  {
    key: 'invoiceState',
    serverKey: 'state',
    label: I18n.t('react.cabinet.common.state'),
    type: SELECT_TYPE,
    options: INVOICE_STATE_OPTIONS,
    value: INVOICE_STATE_OPTIONS[0].value,
  },
  {
    key: 'invoiceForm',
    serverKey: 'form',
    label: I18n.t('react.cabinet.common.type'),
    type: SELECT_TYPE,
    options: INVOICE_FORM_OPTIONS,
    value: INVOICE_FORM_OPTIONS[0].value,
  },
  {
    key: 'profileTypeOptions',
    serverKey: 'ownerable_type',
    label: 'Profile type',
    type: SELECT_TYPE,
    options: CSV_LOGS_OWNERABLE_OPTIONS,
    value: CSV_LOGS_OWNERABLE_OPTIONS[0].value,
  },
  {
    key: 'logsState',
    serverKey: 'logs_state',
    label: I18n.t('react.cabinet.logs.filters.logs_state'),
    type: SELECT_TYPE,
    options: LOGS_STATE_OPTIONS,
    value: LOGS_STATE_OPTIONS[0].value,
  },
  {
    key: 'pushNotificationLogsState',
    serverKey: 'logs_state',
    label: I18n.t('react.cabinet.common.state'),
    type: SELECT_TYPE,
    options: PUSH_NOTIFICATION_LOGS_STATE_OPTIONS,
    value: PUSH_NOTIFICATION_LOGS_STATE_OPTIONS[0].value,
  },
  {
    key: 'courseAccessState',
    serverKey: 'publish_state',
    label: I18n.t('react.common.state'),
    type: SELECT_TYPE,
    options: COURSE_SESSION_PUBLISH_STATE_OPTIONS,
    value: COURSE_SESSION_PUBLISH_STATE_OPTIONS[0].value,
  },
  {
    key: 'membershipAccessState',
    serverKey: 'publish_state',
    label: I18n.t('react.common.state'),
    type: SELECT_TYPE,
    options: MEMBERSHIP_SESSION_PUBLISH_STATE_OPTIONS,
    value: MEMBERSHIP_SESSION_PUBLISH_STATE_OPTIONS[0].value,
  },
  {
    key: 'access',
    serverKey: 'blocked',
    label: I18n.t('react.cabinet.common.access'),
    type: SELECT_TYPE,
    options: ACCESS_OPTIONS,
    value: ACCESS_OPTIONS[0].value,
  },
  {
    key: 'trackingCodeForm',
    serverKey: 'tracking_code_form',
    label: I18n.t('react.cabinet.logs.filters.tracking_code_form'),
    type: SELECT_TYPE,
    options: TRACKING_CODE_FORM_OPTIONS,
    value: TRACKING_CODE_FORM_OPTIONS[0].value,
  },
  {
    key: 'trackingFormType',
    serverKey: 'form',
    label: I18n.t('react.cabinet.logs.filters.form'),
    type: SELECT_TYPE,
    options: TRACKING_FORM_TYPE_OPTIONS,
    value: TRACKING_FORM_TYPE_OPTIONS[0].value,
  },
  {
    key: 'trackingCodesForm',
    serverKey: 'form',
    label: I18n.t('react.cabinet.logs.filters.tracking_code_form'),
    type: SELECT_TYPE,
    options: TRACKING_CODE_FORM_OPTIONS,
    value: TRACKING_CODE_FORM_OPTIONS[0].value,
  },
  {
    key: 'reportState',
    serverKey: 'purpose',
    label: 'Report State',
    type: SELECT_TYPE,
    options: FINANCIAL_REPORTS_REPORT_STATE_OPTIONS,
  },
  {
    key: 'chargingForm',
    serverKey: 'charging_form',
    label: 'Charging Form',
    type: SELECT_TYPE,
    options: MONTHLY_INVOICES_CHARGING_FORM_OPTIONS,
  },
  {
    key: 'teamMemberId',
    serverKey: 'team_member_id',
    label: 'Team member',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'teamMembersStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id'),
  },
  {
    key: 'assignmentForm',
    serverKey: 'form',
    label: 'Form',
    type: SELECT_TYPE,
    options: PRODUCT_ASSIGNMENT_FORM,
  },
  {
    key: 'ownerableType',
    serverKey: 'ownerable_type',
    label: 'Owner Type',
    type: SELECT_TYPE,
    options: PAYOUTS_OWNERABLE_TYPE_OPTIONS,
  },
  {
    key: 'profileType',
    serverKey: 'profile_type',
    label: 'Profile type',
    type: SELECT_TYPE,
    options: PAYOUTS_OWNERABLE_TYPE_OPTIONS,
  },
  {
    key: 'vatSettingOwnerableType',
    serverKey: 'ownerable_type',
    label: 'Ownerable type',
    type: SELECT_TYPE,
    options: VAT_SETTINGS_OWNERABLE_TYPE,
  },
  {
    key: 'eloPublisherId',
    serverKey: 'elo_publisher_id',
    label: 'Elo Publisher',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'eloPublishersStore',
    formatOptions: (list) => filterFormatters.firstLastName(list),
  },
  {
    key: 'reseller',
    serverKey: 'reseller_id',
    label: 'Reseller',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'resellersStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id', 'nameWithSufix']),
  },
  {
    key: 'multipleReseller',
    serverKey: 'reseller_id',
    label: 'Reseller',
    type: SELECT_WITH_MULTIPLE_SEARCH_TYPE,
    store: 'resellersStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id', 'nameWithSufix']),
  },
  {
    key: 'socialWallet',
    serverKey: 'social_wallet',
    label: 'Social Wallet',
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
  },
  {
    key: 'vatFrom',
    serverKey: 'vat_from',
    label: 'Vat From',
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'vatTo',
    serverKey: 'vat_to',
    label: 'Vat to',
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'vatId',
    serverKey: 'vat_no',
    label: 'VAT ID',
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'transferState',
    serverKey: 'state',
    label: I18n.t('react.common.status'),
    type: SELECT_TYPE,
    options: TRANSFER_STATE_OPTIONS,
    value: TRANSFER_STATE_OPTIONS[0].value,
  },
  {
    key: 'vibanTransferState',
    serverKey: 'state',
    label: I18n.t('react.common.status'),
    type: SELECT_TYPE,
    options: VIBAN_TRANSFER_STATE_OPTIONS,
    value: VIBAN_TRANSFER_STATE_OPTIONS[1].value,
  },
  {
    key: 'hasNote',
    serverKey: 'has_note',
    label: 'Has Note',
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'paymentForm',
    serverKey: 'payment_form',
    label: I18n.t('react.common.method'),
    type: SELECT_TYPE,
    options: PAYMENT_FORM_OPTIONS.filter(({ value }) => value !== 'ext_balance'),
    value: PAYMENT_FORM_OPTIONS[0].value,
  },
  {
    key: 'paymentMethod',
    serverKey: 'payment_method',
    label: I18n.t('react.common.method'),
    type: SELECT_TYPE,
    options: PAYMENT_METHOD_OPTIONS,
    value: PAYMENT_METHOD_OPTIONS[0].value,
  },
  {
    key: 'transferProviderAdmin',
    serverKey: 'provider',
    label: I18n.t('react.common.provider'),
    type: SELECT_TYPE,
    options: TRANSFER_PROVIDERS_ADMIN_OPTIONS,
    value: TRANSFER_PROVIDERS.noProvider,
  },
  {
    key: 'transferProviderAdminBase',
    serverKey: 'provider',
    label: I18n.t('react.common.provider'),
    type: SELECT_TYPE,
    options: TRANSFER_PROVIDERS_BASE_ADMIN_OPTIONS,
    value: TRANSFER_PROVIDERS.noProvider,
  },
  {
    key: 'stripeTransferableId',
    serverKey: 'transferable_id',
    label: 'Transferable id',
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'transferableType',
    serverKey: 'transferable_type',
    label: 'Transferable type',
    type: SELECT_TYPE,
    options: TRANSFERABLE_TYPE,
    value: TRANSFERABLE_TYPE[0].value,
  },
  {
    key: 'transferForm',
    serverKey: 'payment_form',
    label: I18n.t('react.common.method'),
    type: SELECT_TYPE,
    options: TRANSFER_FORM_OPTIONS,
    value: TRANSFER_FORM_OPTIONS[0].value,
  },
  {
    key: 'formForTransfer',
    serverKey: 'form',
    label: 'Form',
    type: SELECT_TYPE,
    options: TRANSFER_FORM_OPTIONS,
    value: TRANSFER_FORM_OPTIONS[0].value,
  },
  {
    key: 'contractStatus',
    serverKey: 'status',
    label: I18n.t('react.cabinet.common.status'),
    type: SELECT_TYPE,
    options: CONTRACT_STATE_OPTIONS,
    value: CONTRACT_STATE_OPTIONS[0].value,
  },
  {
    key: 'status',
    serverKey: 'status',
    label: I18n.t('react.cabinet.common.status'),
    type: SELECT_TYPE,
    options: STATUS_FILTER_OPTIONS,
    value: STATUS_FILTER_OPTIONS[0].value,
  },
  {
    key: 'transferStatus',
    serverKey: 'state',
    label: I18n.t('react.cabinet.common.status'),
    type: SELECT_TYPE,
    options: STATUS_TRANSFER_OPTIONS,
    value: STATUS_TRANSFER_OPTIONS[0].value,
  },
  {
    key: 'webhookStatus',
    serverKey: 'status',
    label: I18n.t('react.cabinet.common.status'),
    type: SELECT_TYPE,
    options: WEBHOOK_STATUS_FILTER_OPTIONS,
    value: WEBHOOK_STATUS_FILTER_OPTIONS[0].value,
  },
  {
    key: 'paymentTicketState',
    serverKey: 'state',
    label: I18n.t('react.cabinet.common.state'),
    type: SELECT_TYPE,
    options: PAYMENT_TICKET_STATE_OPTIONS,
    value: PAYMENT_TICKET_STATE_OPTIONS[0].value,
  },
  {
    key: 'payerId',
    serverKey: 'payer_id',
    label: I18n.t('react.cabinet.common.payer'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'payersStore',
    formatOptions: (list) => filterFormatters.firstLastNameEmail(list),
  },
  {
    key: 'couponId',
    serverKey: 'coupon_id',
    label: I18n.t('react.cabinet.common.coupon'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'couponsStore',
  },
  {
    key: 'orderId',
    serverKey: 'order_id',
    label: I18n.t('react.shared.nav.orders'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'ordersStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id']),
  },
  {
    key: 'multipleOrderId',
    serverKey: 'order_id',
    label: I18n.t('react.shared.nav.orders'),
    type: SELECT_WITH_MULTIPLE_SEARCH_TYPE,
    store: 'ordersStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id']),
  },
  {
    key: 'publisherId',
    serverKey: 'publisher_id',
    label: I18n.t('react.cabinet.orders.publisher'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'publishersStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id', 'fullName']),
  },
  {
    key: 'periodType',
    serverKey: 'period_type',
    label: I18n.t('react.cabinet.common.plan'),
    type: SELECT_TYPE,
    options: PERIOD_ORDER_TYPE_OPTIONS,
    value: PAYMENT_PLANS.oneTime,
  },
  {
    key: 'chargesCount',
    serverKey: 'charges_count',
    label: I18n.t('react.cabinet.orders.billings'),
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'userProfiles',
    serverKey: 'full_name',
    label: 'Users',
    type: TEXT_INPUT_TYPE,
    store: 'userProfilesStore',
  },
  {
    key: 'sellerId',
    serverKey: 'seller_id',
    label: I18n.t('shared.common.seller'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'sellersStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id', 'username']),
  },
  {
    key: 'categoryId',
    serverKey: 'category_id',
    label: I18n.t('react.shared.category'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'contentPageCategoriesStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id']),
  },
  {
    key: 'categoryKey',
    serverKey: 'category_key',
    label: 'Category',
    type: SELECT_TYPE,
    options: PRODUCT_TAXONOMOY_CATEGORIES_OPTIONS,
  },
  {
    key: 'productId',
    serverKey: 'product_id',
    label: I18n.t('shared.common.product'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'productsStore',
  },
  {
    key: 'courseId',
    serverKey: 'product_id',
    label: I18n.t('shared.common.product'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'productsStore',
    requestParams: { form: PRODUCT_TYPE_IDS.course },
  },
  {
    key: 'lessonId',
    serverKey: 'lesson_id',
    label: I18n.t('react.cabinet.quiz.lesson'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'lessonsStore',
  },
  {
    key: 'ticketId',
    serverKey: 'ticket_id',
    label: I18n.t('react.cabinet.common.ticket'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'ticketsStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['name', 'locationShortName']),
  },
  {
    key: 'ticketDateId',
    serverKey: 'ticket_date_id',
    label: I18n.t('react.cabinet.common.ticket_date'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'ticketDatesStore',
    formatOptions: (list) => formatTicketDatesOptions(list),
  },
  {
    key: 'affiliateProgram',
    serverKey: 'affiliate_program_id',
    label: I18n.t('shared.common.affiliate_program'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'affiliateProgramsStore',
  },
  {
    key: 'affiliteNodeState',
    serverKey: 'state',
    label: I18n.t('react.cabinet.common.state'),
    type: SELECT_TYPE,
    options: AFFILIATE_NODE_STATE_OPTIONS,
    value: AFFILIATE_NODE_STATE_OPTIONS[0].value,
  },
  {
    key: 'seller',
    serverKey: 'receiver_id',
    label: I18n.t('shared.common.seller'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'sellersStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id', 'username']),
  },
  {
    serverKey: 'program_query',
    label: I18n.t('shared.common.program'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'affiliateProgramsStore',
  },
  {
    key: 'processedTransfer',
    serverKey: 'processed',
    label: I18n.t('react.cabinet.logs.filters.options.logs_state.processed'),
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'transferKind',
    serverKey: 'kind',
    label: 'Kind',
    type: SELECT_TYPE,
    options: TRANSFER_KIND_OPTIONS,
    value: TRANSFER_KIND_OPTIONS[0].value,
  },
  {
    key: 'listType',
    serverKey: 'list_type',
    label: I18n.t('react.common.types'),
    type: SELECT_TYPE,
    options: SELLER_LIST_TYPE_OPTIONS,
    value: SELLER_LIST_TYPE_OPTIONS[0].value,
  },
  {
    key: 'listTypeAdmin',
    serverKey: 'list_type',
    label: 'List type',
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: ADMIN_LIST_TYPE_OPTIONS,
  },
  {
    key: 'affiliateRedirectionURL',
    serverKey: 'url',
    label: I18n.t('shared.common.url'),
    type: TEXT_INPUT_TYPE,
    placeholder: I18n.t('shared.common.url'),
  },
  {
    key: 'fallbackURL',
    serverKey: 'fallback_url',
    label: I18n.t('react.cabinet.content_pages.filters.fallback_url'),
    type: TEXT_INPUT_TYPE,
    placeholder: I18n.t('react.cabinet.content_pages.filters.fallback_url'),
  },
  {
    key: 'countryCode',
    serverKey: 'country_code',
    label: I18n.t('react.cabinet.common.country'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'countriesStore',
    searchLocally: true,
  },
  {
    key: 'countryOfEvent',
    serverKey: 'country_of_event',
    label: 'Country of event',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'countriesStore',
    searchLocally: true,
  },
  {
    key: 'payerCountryCode',
    serverKey: 'country_code',
    label: I18n.t('react.cabinet.common.payer_country'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'countriesStore',
    searchLocally: true,
  },
  {
    key: 'transferCountryCode',
    serverKey: 'transfer_country_code',
    label: 'Transaction country',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'countriesStore',
    searchLocally: true,
  },
  {
    key: 'lifetime',
    serverKey: 'lifetime',
    label: I18n.t('react.shared.affiliateProgram.lifetime'),
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'publishState',
    serverKey: 'publish_state',
    label: I18n.t('react.cabinet.common.publishState'),
    type: SELECT_TYPE,
    options: LESSON_STATUS_PUBLISH_STATE_OPTIONS,
    value: LESSON_STATUS_PUBLISH_STATE_OPTIONS[0].value,
  },
  {
    key: 'notifyState',
    serverKey: 'notify_state',
    label: I18n.t('react.cabinet.common.notifyState'),
    type: SELECT_TYPE,
    options: LESSON_STATUS_NOTIFY_STATE_OPTIONS,
    value: LESSON_STATUS_NOTIFY_STATE_OPTIONS[0].value,
  },
  {
    key: 'notifyDate',
    serverKey: {
      key: 'notify_date',
      from: 'notify_date_from',
      till: 'notify_date_till',
    },
    label: I18n.t('react.cabinet.common.notifyTime'),
    type: DATE_RANGE_TYPE,
    placeholder: I18n.t('react.cabinet.common.notifyTime'),
    value: {},
  },
  {
    key: 'startDate',
    serverKey: {
      from: 'start_from',
      till: 'start_till',
    },
    label: I18n.t('shared.common.start_date'),
    type: DATE_RANGE_TYPE,
    placeholder: I18n.t('shared.common.start_date'),
    value: {},
  },
  {
    key: 'endDate',
    serverKey: {
      from: 'end_from',
      till: 'end_till',
    },
    label: I18n.t('shared.common.end_date'),
    type: DATE_RANGE_TYPE,
    placeholder: I18n.t('shared.common.end_date'),
    value: {},
  },
  {
    key: 'read',
    serverKey: 'read',
    label: I18n.t('react.common.show'),
    type: SELECT_TYPE,
    options: SHOW_NOTIFICATIONS_OPTIONS,
    value: SHOW_NOTIFICATIONS_OPTIONS[0].value,
  },
  {
    key: 'notificableType',
    serverKey: 'notific_key',
    label: I18n.t('react.common.types'),
    type: SELECT_TYPE,
    options: NOTIFICABLE_TYPES_OPTIONS,
    value: NOTIFICABLE_TYPES_OPTIONS[0].value,
  },
  {
    key: 'payerNotificableType',
    serverKey: 'notific_key',
    label: I18n.t('react.common.types'),
    type: SELECT_TYPE,
    options: PAYER_NOTIFICABLE_TYPES_OPTIONS,
    value: PAYER_NOTIFICABLE_TYPES_OPTIONS[0].value,
  },
  {
    key: 'pushNotificationNotificType',
    serverKey: 'notific_key',
    label: I18n.t('react.common.types'),
    type: SELECT_TYPE,
    options: PUSH_NOTIFICATION_NOTIFICABLE_TYPES_OPTIONS,
    value: PUSH_NOTIFICATION_NOTIFICABLE_TYPES_OPTIONS[0].value,
  },
  {
    key: 'provider',
    serverKey: 'provider',
    label: I18n.t('react.common.provider'),
    type: SELECT_TYPE,
    options: CONTACT_PROVIDER_OPTIONS,
  },
  {
    key: 'embedType',
    serverKey: 'embed_type',
    label: 'Embed type',
    type: SELECT_TYPE,
    options: EMBED_TYPE_OPTIONS,
  },
  {
    key: 'info',
    serverKey: 'info',
    label: 'Info',
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'params',
    serverKey: 'params',
    label: I18n.t('react.cabinet.logs.tables.params'),
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'response',
    serverKey: 'response',
    label: I18n.t('react.cabinet.logs.tables.response'),
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'errorMsg',
    serverKey: 'error_msg',
    label: I18n.t('react.cabinet.logs.tables.error_msg'),
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'errorData',
    serverKey: 'error_data',
    label: I18n.t('react.cabinet.logs.tables.error_data'),
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'formName',
    serverKey: 'form_name',
    label: I18n.t('react.shared.form_name'),
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'formType',
    serverKey: 'with_payer',
    label: I18n.t('react.shared.type'),
    type: SELECT_TYPE,
    options: CONTACT_FORM_TYPE_OPTIONS,
  },
  {
    key: 'multiPaymentForm',
    serverKey: 'form',
    label: I18n.t('react.common.method'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: PAYMENT_FORM_OPTIONS.filter(({ value }) => value !== 'pay_later' && value !== 'not_assigned'),
    value: [],
  },
  {
    key: 'multiPaymentFormDunning',
    serverKey: 'payment_form',
    label: I18n.t('react.common.method'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: PAYMENT_FORM_OPTIONS.filter(
      ({ value }) => ['free', 'not_assigned', 'ext_balance', 'bank_account'].indexOf(value) === -1
    ),
    value: [],
  },
  {
    key: 'multiProviderAdmin',
    serverKey: 'provider',
    label: I18n.t('react.common.provider'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: TRANSFER_PROVIDERS_ADMIN_OPTIONS,
    value: [],
  },
  {
    key: 'vat',
    serverKey: {
      key: 'vat',
      from: 'vat_from',
      to: 'vat_to',
    },
    label: 'Vat',
    type: NUMBER_RANGE_TYPE,
    value: {},
    min: 0,
    max: 100,
  },
  {
    key: 'chargebackRate',
    serverKey: {
      key: 'chargebackRate',
      from: 'chargebacks_rate_from',
      to: 'chargebacks_rate_to',
    },
    label: 'Chargeback rate',
    type: NUMBER_RANGE_TYPE,
    value: {},
    min: 0,
    max: 100,
  },
  {
    key: 'refundRate',
    serverKey: {
      key: 'refundRate',
      from: 'refunds_rate_from',
      to: 'refunds_rate_to',
    },
    label: 'Refund rate',
    type: NUMBER_RANGE_TYPE,
    value: {},
    min: 0,
    max: 100,
  },
  {
    key: 'cancelledRate',
    serverKey: {
      key: 'cancelledRate',
      from: 'canceled_rate_from',
      to: 'canceled_rate_to',
    },
    label: 'Cancelled rate',
    type: NUMBER_RANGE_TYPE,
    value: {},
    min: 0,
    max: 100,
  },
  {
    key: 'waitingRate',
    serverKey: {
      key: 'waitingRate',
      from: 'waiting_rate_from',
      to: 'waiting_rate_to',
    },
    label: 'Waiting rate',
    type: NUMBER_RANGE_TYPE,
    value: {},
    min: 0,
    max: 100,
  },
  {
    key: 'sepa',
    serverKey: {
      key: 'sepa',
      from: 'sepa_from',
      to: 'sepa_to',
    },
    label: 'Sepa',
    type: NUMBER_RANGE_TYPE,
    value: {},
    min: 0,
    max: 100,
  },
  {
    key: 'creditCard',
    serverKey: {
      key: 'credit_card',
      from: 'credit_card_from',
      to: 'credit_card_to',
    },
    label: 'Credit card',
    type: NUMBER_RANGE_TYPE,
    value: {},
    min: 0,
    max: 100,
  },
  {
    key: 'paypalRest',
    serverKey: {
      key: 'paypal_rest',
      from: 'paypal_rest_from',
      to: 'paypal_rest_to',
    },
    label: 'Paypal rest',
    type: NUMBER_RANGE_TYPE,
    value: {},
    min: 0,
    max: 100,
  },
  {
    key: 'paypalNvp',
    serverKey: {
      key: 'paypal_nvp',
      from: 'paypal_nvp_from',
      to: 'paypal_nvp_to',
    },
    label: 'Paypal nvp',
    type: NUMBER_RANGE_TYPE,
    value: {},
    min: 0,
    max: 100,
  },
  {
    key: 'bankWire',
    serverKey: {
      key: 'bank_wire',
      from: 'bank_wire_from',
      to: 'bank_wire_to',
    },
    label: 'Bank wire',
    type: NUMBER_RANGE_TYPE,
    value: {},
    min: 0,
    max: 100,
  },
  {
    key: 'sofort',
    serverKey: {
      key: 'sofort',
      from: 'sofort_from',
      to: 'sofort_to',
    },
    label: 'Sofort',
    type: NUMBER_RANGE_TYPE,
    value: {},
    min: 0,
    max: 100,
  },
  {
    key: 'p24',
    serverKey: {
      key: 'p_24',
      from: 'p_24_from',
      to: 'p_24_to',
    },
    label: 'Przelewy24',
    type: NUMBER_RANGE_TYPE,
    value: {},
    min: 0,
    max: 100,
  },
  {
    key: 'total',
    serverKey: {
      key: 'total',
      from: 'total_from',
      to: 'total_to',
    },
    label: 'Total',
    type: NUMBER_RANGE_TYPE,
    value: {},
  },
  {
    key: 'amount',
    serverKey: {
      key: 'amount',
      from: 'amount_from',
      to: 'amount_to',
    },
    label: 'Amount',
    type: NUMBER_RANGE_TYPE,
    value: {},
  },
  {
    key: 'reviewState',
    serverKey: 'review_state',
    label: I18n.t('react.cabinet.product.list.review_state'),
    type: SELECT_TYPE,
    options: PRODUCT_REVIEW_STATES,
  },
  {
    key: 'bankAccountInternalState',
    serverKey: 'int_state',
    label: 'Internal state',
    type: SELECT_TYPE,
    options: BANK_ACCOUNT_INTERNAL_STATE_OPTIONS,
  },
  {
    key: 'bankAccountExternalState',
    serverKey: 'ext_state',
    label: 'External state',
    type: SELECT_TYPE,
    options: BANK_ACCOUNT_EXTERNAL_STATE_OPTIONS,
  },
  {
    key: 'bankAccountLegitimationType',
    serverKey: 'type',
    label: 'Type',
    type: SELECT_TYPE,
    options: BANK_ACCOUNT_LEGITIMATION_TYPE_OPTIONS,
  },
  {
    key: 'planId',
    serverKey: 'plan_id',
    label: I18n.t('react.cabinet.common.plan'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'plansStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id', 'name']),
  },
  {
    key: 'themeAppliedMoreThan',
    serverKey: 'applied_more_than',
    label: 'Applied more than',
    type: NUMBER_INPUT_TYPE,
    value: 0,
    min: 0,
  },
  {
    key: 'default',
    serverKey: 'default',
    label: 'Default',
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'template',
    serverKey: 'template',
    label: 'Template',
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'trial',
    serverKey: 'trial',
    label: 'Trial',
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'themeForm',
    serverKey: 'form',
    label: 'Form',
    type: SELECT_TYPE,
    options: THEME_FORMS,
  },
  {
    key: 'sellerHasProductsMoreThan',
    serverKey: 'products_more_than',
    label: 'Seller has products more than',
    type: NUMBER_INPUT_TYPE,
    value: 0,
    min: 0,
  },
  {
    key: 'sellerHasOrdersMoreThan',
    serverKey: 'orders_more_than',
    label: 'Seller has orders more than',
    type: NUMBER_INPUT_TYPE,
    value: 0,
    min: 0,
  },
  {
    key: 'monthlyInvoiceState',
    serverKey: 'state',
    label: I18n.t('react.common.state'),
    type: SELECT_TYPE,
    store: 'monthlyInvoicesStore',
    options: MONTHLY_INVOICES_STATE_OPTIONS,
  },
  {
    key: 'monthlyInvoiceForm',
    serverKey: 'form',
    label: I18n.t('react.common.type'),
    type: SELECT_TYPE,
    store: 'monthlyInvoicesStore',
    options: MONTHLY_INVOICES_FORM_OPTIONS,
  },
  {
    key: 'monthlyInvoiceVersion',
    serverKey: 'invoice_version',
    label: 'Version',
    type: SELECT_TYPE,
    store: 'monthlyInvoicesStore',
    options: MONTHLY_INVOICES_VERSION_OPTIONS,
  },
  {
    key: 'externalTransfersKind',
    serverKey: 'kind',
    label: 'Kind',
    type: SELECT_TYPE,
    options: EXTERNAL_TRANSFERS_KIND_OPTIONS,
  },
  {
    key: 'internalStatus',
    serverKey: 'internal_status',
    label: 'Internal status',
    type: SELECT_TYPE,
    options: INTERNAL_STATUS_OPTIONS,
  },
  {
    key: 'isImported',
    serverKey: 'is_copy',
    label: I18n.t('react.shared.imported'),
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
  },
  {
    key: 'cookiesConsentCategory',
    serverKey: 'cookies_consent_category_id',
    label: 'Cookies Consent Category',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'cookiesConsentCategoriesStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id', 'name']),
  },
  {
    key: 'embeddableItemForm',
    serverKey: 'form',
    label: I18n.t('react.shared.type'),
    type: SELECT_TYPE,
    store: 'embeddableItemsStore',
    options: EMBEDDABLE_ITEMS_FORM_OPTIONS,
  },
  {
    key: 'embeddableItemPurpose',
    serverKey: 'purpose',
    label: I18n.t('react.shared.linked_to'),
    type: SELECT_TYPE,
    store: 'embeddableItemsStore',
    options: EMBEDDABLE_ITEMS_PURPOSE_OPTIONS,
  },
  {
    key: 'onlyWithRefunds',
    serverKey: 'only_with_refunds',
    label: 'Only with refunds',
    type: SELECT_TYPE,
    options: [
      {
        ...YES_NO_OPTIONS[0],
        value: 1,
      },
    ],
  },
  {
    key: 'onlyWithChargebacks',
    serverKey: 'only_with_chargebacks',
    label: 'Only with chargebacks',
    type: SELECT_TYPE,
    options: [
      {
        ...YES_NO_OPTIONS[0],
        value: 1,
      },
    ],
  },
  {
    key: 'currencyId',
    serverKey: 'currency_id',
    label: I18n.t('react.common.currency'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'currenciesStore',
    formatOptions: formatCurrencySelectOptions,
  },
  {
    key: 'mandatory',
    serverKey: 'mandatory',
    label: I18n.t('react.cabinet.opt_in.list.mandatory'),
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
  },
  {
    key: 'activeTheme',
    serverKey: 'default',
    label: `${I18n.t('shared.common.active')} ${I18n.t('react.common.theme').toLowerCase()}`,
    type: SELECT_TYPE,
    options: [YES_NO_OPTIONS[0]],
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'trackingCodeType',
    serverKey: 'form',
    label: I18n.t('react.cabinet.tracking_codes.list.form'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: TRACKING_CODE_TYPES_OPTIONS,
  },
  {
    key: 'fieldType',
    serverKey: 'field_type',
    label: I18n.t('react.cabinet.opt_in.list.type'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: CUSTOM_FIELD_TYPE_OPTIONS,
    value: [],
  },
  {
    key: 'customFieldIntegration',
    serverKey: 'provider',
    label: I18n.t('react.cabinet.opt_in.list.app'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: CUSTOM_FIELDS_INTEGRATIONS_OPTIONS,
    value: [],
  },
  {
    key: 'funnelId',
    serverKey: 'funnel_id',
    label: I18n.t('react.cabinet.funnels.title'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'funnelsStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id', 'name']),
  },
  {
    key: 'funnelNodeId',
    serverKey: 'funnel_node_id',
    label: I18n.t('react.cabinet.funnels.funnel_node'),
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'walletId',
    serverKey: 'wallet_id',
    label: 'Wallet id',
    type: NUMBER_INPUT_TYPE,
  },
  {
    key: 'needActionTransfersState',
    serverKey: 'state',
    label: I18n.t('react.common.state'),
    type: SELECT_TYPE,
    options: NEED_ACTION_TRANSFERS_OPTIONS,
  },
  {
    key: 'apiConnectionForm',
    serverKey: 'form',
    label: I18n.t('shared.common.form'),
    type: SELECT_TYPE,
    options: API_CONNECTIONS_FORM_OPTIONS,
    value: API_CONNECTIONS_FORM_OPTIONS[0].value,
  },
  {
    key: 'webhookPurpose',
    serverKey: 'purpose',
    label: 'Purpose',
    type: SELECT_TYPE,
    options: WEBHOOK_PURPOSE_OPTIONS,
    value: WEBHOOK_PURPOSE_OPTIONS[0].value,
  },
  {
    key: 'orderBumpType',
    serverKey: 'view_type',
    label: I18n.t('react.shared.type'),
    type: SELECT_TYPE,
    options: ORDER_BUMPS_VIEW_TYPES_OPTIONS,
    value: ORDER_BUMPS_VIEW_TYPES_OPTIONS[0].value,
  },
  {
    // ORDERS AND TRANSFERS FILTER
    key: 'transferCreatedAtTime',
    serverKey: {
      key: 'created',
      from_time: 'created_from_time',
      till_time: 'created_till_time',
      created_from_time: 'created_from_time',
      created_till_time: 'created_till_time',
    },
    label: I18n.t('shared.common.created'),
    type: DATE_TIME_RANGE_TYPE,
    value: {},
  },
  {
    key: 'createdRelativeAtTime',
    serverKey: {
      key: 'created_relative_at',
      created_relative_at_period: 'created_relative_at_period',
      created_relative_at_time: 'created_relative_at_time',
    },
    label: I18n.t('react.shared.created_relative_date'),
    type: DATE_RELATIVE_RANGE_TYPE,
    value: {},
  },
  {
    key: 'orderCreatedAtTime',
    serverKey: {
      key: 'created',
      from_time: 'created_from_time',
      till_time: 'created_till_time',
      created_from_time: 'created_from_time',
      created_till_time: 'created_till_time',
    },
    label: I18n.t('shared.common.created'),
    type: DATE_TIME_RANGE_TYPE,
    value: {},
  },
  {
    key: 'activatedAt',
    serverKey: {
      key: 'activated',
      from: 'activated_from',
      till: 'activated_till',
      activated_from: 'activated_from',
      activated_till: 'activated_till',
    },
    label: I18n.t('react.cabinet.common.activated'),
    type: DATE_RANGE_TYPE,
    value: {},
  },
  {
    key: 'activatedAtTime',
    serverKey: {
      key: 'activated',
      from_time: 'activated_from_time',
      till_time: 'activated_till_time',
      activated_from_time: 'activated_from_time',
      activated_till_time: 'activated_till_time',
    },
    label: I18n.t('react.cabinet.common.activated'),
    type: DATE_TIME_RANGE_TYPE,
    value: {},
  },
  {
    key: 'successAt',
    label: I18n.t('shared.common.success'),
    serverKey: {
      key: 'success',
      from: 'success_from',
      till: 'success_till',
      success_from: 'success_from',
      success_till: 'success_till',
    },
    type: DATE_RANGE_TYPE,
    value: {},
  },
  {
    key: 'successAtTime',
    label: I18n.t('shared.common.success'),
    serverKey: {
      key: 'success',
      from_time: 'success_from_time',
      till_time: 'success_till_time',
      success_from: 'success_from_time',
      success_till: 'success_till_time',
      success_from_time: 'success_from_time',
      success_till_time: 'success_till_time',
    },
    type: DATE_TIME_RANGE_TYPE,
    value: {},
  },
  {
    key: 'successRelativeAtTime',
    serverKey: {
      key: 'success_relative_at',
      success_relative_at_period: 'success_relative_at_period',
      success_relative_at_time: 'success_relative_at_time',
    },
    label: I18n.t('react.shared.success_relative_date'),
    type: DATE_RELATIVE_RANGE_TYPE,
    value: {},
  },
  {
    key: 'payoutsPaused',
    serverKey: 'payouts_paused',
    label: 'Payouts paused',
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'payoutsBlocked',
    serverKey: 'payouts_blocked',
    label: 'Payouts blocked',
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'hideFree',
    serverKey: 'hide_free',
    label: I18n.t('react.shared.hide_free'),
    type: SELECT_TYPE,
    options: [YES_NO_OPTIONS[0]],
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'multiCurrencyId',
    serverKey: 'currency_id',
    label: I18n.t('react.common.currency'),
    type: SELECT_WITH_MULTIPLE_SEARCH_TYPE,
    store: 'currenciesStore',
    formatOptions: (list) => {
      const labels = getCurrencyLabels()
      return list.map(({ id, key }) => ({
        value: id,
        label: labels[key],
      }))
    },
  },
  {
    key: 'multiPaymentState',
    serverKey: 'payment_state',
    label: I18n.t('react.cabinet.common.paymentState'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: PAYMENT_STATE_OPTIONS,
    value: PAYMENT_STATE_OPTIONS[0].value,
  },
  {
    key: 'orderExcludedNotCompleted',
    serverKey: 'exclude_not_completed',
    label: I18n.t('shared.common.exclude_not_completed'),
    type: SELECT_TYPE,
    options: [YES_NO_OPTIONS[0]],
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'defaultManager',
    serverKey: 'default',
    label: 'Current Manager Log',
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'paymentFormType',
    serverKey: 'transferable_type',
    label: I18n.t('react.common.type'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: PAYMENT_FORM_TYPE_OPTIONS,
    value: PAYMENT_FORM_TYPE_OPTIONS[0].value,
  },
  {
    key: 'paymentFormTypeForNS',
    serverKey: 'transferable_type',
    label: I18n.t('react.common.type'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: PAYMENT_FORM_TYPE_OPTIONS_FOR_NORMAL_SELLER,
    value: PAYMENT_FORM_TYPE_OPTIONS_FOR_NORMAL_SELLER[0].value,
  },
  {
    key: 'successTransfer',
    serverKey: 'success_transfer',
    label: I18n.t('react.cabinet.common.success_transfer'),
    type: SELECT_TYPE,
    options: [YES_NO_OPTIONS[0]],
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'multiTransferState',
    serverKey: 'state',
    label: I18n.t('react.common.state'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: TRANSFER_STATE_OPTIONS,
    value: [],
  },
  {
    key: 'multiTransferStateAdmin',
    serverKey: 'state',
    label: I18n.t('react.common.state'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: TRANSFER_STATE_ADMIN_OPTIONS,
    value: [],
  },
  {
    key: 'multiTransferCashoutStateAdmin',
    serverKey: 'state',
    label: I18n.t('react.common.state'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: CASHOUT_TRANSFER_STATE_OPTIONS,
    value: [],
  },
  {
    key: 'multiOrderPaymentForm',
    serverKey: 'payment_form',
    label: I18n.t('react.common.method'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: PAYMENT_FORM_OPTIONS.filter(({ value }) => value !== 'ext_balance'),
    value: [],
  },
  {
    key: 'multiTransferForm',
    serverKey: 'payment_form',
    label: I18n.t('react.common.method'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: TRANSFER_FORM_OPTIONS,
    value: [],
  },
  {
    key: 'multiPeriodType',
    serverKey: 'period_type',
    label: I18n.t('react.cabinet.common.plan'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: PERIOD_ORDER_TYPE_OPTIONS,
    value: [],
  },
  {
    key: 'multiPricingPlanId',
    serverKey: 'pricing_plan_id',
    label: I18n.t('react.shared.transfers.csv.pricing_plan_id'),
    type: SELECT_WITH_MULTIPLE_SEARCH_TYPE,
    store: 'pricingPlansStore',
  },
  {
    key: 'chargesCountRange',
    serverKey: {
      key: 'charges_count',
      from: 'charges_count_from',
      to: 'charges_count_to',
      placeholderFrom: I18n.t('shared.common.from'),
      placeholderTo: I18n.t('shared.common.till'),
    },
    label: I18n.t('react.cabinet.orders.billings'),
    type: NUMBER_RANGE_TYPE,
    value: {},
    min: 0,
    max: 10000,
  },
  {
    key: 'transferProvider',
    serverKey: 'provider',
    label: I18n.t('react.common.provider'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: TRANSFER_PROVIDERS_OPTIONS,
    value: TRANSFER_PROVIDERS.noProvider,
  },
  {
    key: 'transferProviderBase',
    serverKey: 'provider',
    label: I18n.t('react.common.provider'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: TRANSFER_PROVIDERS_BASE_OPTIONS,
    value: TRANSFER_PROVIDERS.noProvider,
  },
  {
    key: 'transferCountry',
    serverKey: 'transfer_country_code',
    label: I18n.t('react.cabinet.common.transfer_payer_country'),
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'countriesStore',
    searchLocally: true,
  },
  {
    key: 'invoiceDate',
    serverKey: {
      key: 'created',
      from_time: 'invoice_created_from_time',
      till_time: 'invoice_created_till_time',
      created_from_time: 'invoice_created_from_time',
      created_till_time: 'invoice_created_till_time',
    },
    label: I18n.t('react.cabinet.common.invoice_date'),
    type: DATE_TIME_RANGE_TYPE,
    value: {},
  },
  {
    key: 'multiCouponId',
    serverKey: 'coupon_id',
    label: I18n.t('react.cabinet.common.coupon'),
    type: SELECT_WITH_MULTIPLE_SEARCH_TYPE,
    store: 'couponsStore',
  },
  {
    key: 'payoutId',
    serverKey: 'payout_id',
    label: I18n.t('react.cabinet.transfer.payout_id'),
    type: SELECT_WITH_MULTIPLE_SEARCH_TYPE,
    store: 'payoutsStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id', 'number']),
  },
  {
    key: 'multiPayerId',
    serverKey: 'payer_id',
    label: I18n.t('react.cabinet.common.payer'),
    type: SELECT_WITH_MULTIPLE_SEARCH_TYPE,
    store: 'payersStore',
    formatOptions: (list) => filterFormatters.firstLastNameEmail(list),
  },
  {
    key: 'multiProductId',
    serverKey: 'product_id',
    label: I18n.t('shared.common.product'),
    type: SELECT_WITH_MULTIPLE_SEARCH_TYPE,
    store: 'productsStore',
  },
  {
    key: 'multiProductCategory',
    serverKey: 'product_group_id',
    label: I18n.t('react.shared.transfers.csv.product_category'),
    type: SELECT_WITH_MULTIPLE_SEARCH_TYPE,
    store: 'productGroupsStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['title']),
  },
  {
    key: 'multiProductIntName',
    serverKey: 'internal_name',
    label: I18n.t('react.cabinet.product.form.internal_name'),
    type: SELECT_WITH_MULTIPLE_SEARCH_TYPE,
    store: 'internalNamesStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'name'),
  },
  {
    key: 'multiTicketId',
    serverKey: 'ticket_id',
    label: I18n.t('react.cabinet.common.ticket'),
    type: SELECT_WITH_MULTIPLE_SEARCH_TYPE,
    store: 'ticketsStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['name', 'locationShortName']),
  },
  {
    key: 'multiAffiliateProgram',
    serverKey: 'affiliate_program_id',
    label: I18n.t('shared.common.affiliate_program'),
    type: SELECT_WITH_MULTIPLE_SEARCH_TYPE,
    store: 'affiliateProgramsStore',
  },
  {
    key: 'multiPublisherId',
    serverKey: 'publisher_id',
    label: I18n.t('react.cabinet.orders.publisher'),
    type: SELECT_WITH_MULTIPLE_SEARCH_TYPE,
    store: 'publishersStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id', 'fullName']),
  },
  {
    key: 'multiSalesMemberId',
    serverKey: 'sales_team_member_id',
    label: I18n.t('react.cabinet.deals.form.sales_member'),
    type: SELECT_WITH_MULTIPLE_SEARCH_TYPE,
    store: 'salesTeamMembersStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id', 'fullName']),
  },
  {
    key: 'multiOrderTypes',
    serverKey: 'upsell_type',
    label: I18n.t('react.cabinet.orders.order_type'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: ORDER_TYPES_OPTIONS,
    value: [],
  },
  {
    key: 'multiFunnelId',
    serverKey: 'funnel_id',
    label: I18n.t('react.cabinet.funnels.title'),
    type: SELECT_WITH_MULTIPLE_SEARCH_TYPE,
    store: 'funnelsStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id', 'name']),
  },
  {
    key: 'multiFunnelNodeId',
    serverKey: 'funnel_node_id',
    label: I18n.t('react.cabinet.funnels.funnel_node'),
    type: SELECT_WITH_MULTIPLE_CREATABLE_TYPE,
    store: 'funnelNodesStore',
  },
  {
    key: 'campaignId',
    serverKey: 'campaign',
    label: I18n.t('react.cabinet.common.camp_id'),
    type: SELECT_WITH_MULTIPLE_CREATABLE_TYPE,
    store: 'campaignStore',
  },
  {
    key: 'sharingForm',
    serverKey: 'form',
    label: 'Sharing form',
    type: SELECT_TYPE,
    options: SHARABLE_FORM_OPTIONS,
  },
  {
    key: 'sharableType',
    serverKey: 'sharable_type',
    label: 'Sharable type',
    type: SELECT_TYPE,
    options: SHARABLE_TYPE_OPTIONS,
  },
  {
    key: 'batchId',
    serverKey: 'batch_id',
    label: 'Batch id',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'syncProfileBatchesStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id']),
  },
  {
    key: 'firstOrderAtTime',
    serverKey: {
      key: 'first_order',
      from_time: 'first_order_from_time',
      till_time: 'first_order_till_time',
      first_order_from: 'first_order_from_time',
      first_order_till: 'first_order_till_time',
      first_order_from_time: 'first_order_from_time',
      first_order_till_time: 'first_order_till_time',
    },
    label: I18n.t('shared.common.created'),
    type: DATE_TIME_RANGE_TYPE,
    value: {},
  },
  {
    key: 'chargedAt',
    serverKey: {
      key: 'charge',
      from: 'charge_from',
      till: 'charge_till',
      charge_from: 'charge_from',
      charge_till: 'charge_till',
    },
    label: I18n.t('shared.common.charged'),
    type: DATE_RANGE_TYPE,
    value: {},
  },
  {
    key: 'chargedAtTime',
    serverKey: {
      key: 'charge',
      from_time: 'charge_from_time',
      till_time: 'charge_till_time',
      charge_from: 'charge_from_time',
      charge_till: 'charge_till_time',
      charge_from_time: 'charge_from_time',
      charge_till_time: 'charge_till_time',
    },
    label: I18n.t('shared.common.charged'),
    type: DATE_TIME_RANGE_TYPE,
    value: {},
  },
  {
    key: 'createdAtTime',
    serverKey: {
      key: 'created',
      from_time: 'created_from_time',
      till_time: 'created_till_time',
      created_from_time: 'created_from_time',
      created_till_time: 'created_till_time',
    },
    label: I18n.t('shared.common.created'),
    type: DATE_TIME_RANGE_TYPE,
    value: {},
  },
  {
    key: 'transferId',
    serverKey: 'transfer_id',
    label: 'Transfer id',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'transfersStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id', ['id', 'state', 'form']),
  },
  {
    key: 'eloPublisherPlanId',
    serverKey: 'elo_publisher_plan_id',
    label: 'Elo pub. plan',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'eloPublisherPlansStore',
    formatOptions: (list) => formatSelectCustomOptions(list, 'id'),
  },
  {
    key: 'communityCreatedAt',
    serverKey: {
      key: 'created',
      from: 'created_from_date',
      till: 'created_till_date',
    },
    label: I18n.t('shared.common.created'),
    type: DATE_RANGE_TYPE,
    value: {},
  },
  {
    key: 'createdRelativeAt',
    serverKey: {
      key: 'created_relative',
      created_relative_period: 'created_relative_period',
      created_relative_time: 'created_relative_time',
    },
    label: I18n.t('react.shared.created_relative_date'),
    type: DATE_RELATIVE_RANGE_TYPE,
    value: {},
  },
  {
    key: 'subsChargesKind',
    serverKey: 'kind',
    label: 'kind',
    type: SELECT_TYPE,
    options: SUBS_CHARGES_KIND_OPTIONS,
  },
  {
    key: 'cancellable',
    serverKey: 'cancellable',
    label: I18n.t('react.shared.is_cancellable'),
    type: SELECT_TYPE,
    options: YES_OPTION,
    value: YES_OPTION[0].value,
  },
  {
    key: 'powerSeller',
    serverKey: 'power_seller',
    label: 'Power Seller',
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'strategyStage',
    serverKey: 'strategy_stage',
    label: I18n.t('react.cabinet.common.strategy_stage'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: getStrategyStageOptions('admin'),
  },
  {
    key: 'hasNotCollectedReason',
    serverKey: 'not_collected_reason',
    label: I18n.t('react.cabinet.dunning.table.not_collected_reason'),
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
  },
  {
    key: 'sellerStrategyStage',
    serverKey: 'strategy_stage',
    label: I18n.t('react.cabinet.common.strategy_stage'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: getStrategyStageOptions('seller'),
  },
  {
    key: 'offlineCountryCode',
    serverKey: 'offline_country_code',
    label: 'Offline Country Code',
    type: SELECT_WITH_MULTIPLE_SEARCH_TYPE,
    store: 'countriesStore',
  },
  {
    key: 'ticketType',
    serverKey: 'online',
    label: 'Online',
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
  },
  {
    key: 'overDue',
    serverKey: {
      key: 'overdue',
      from: 'overdue_from',
      to: 'overdue_to',
    },
    label: I18n.t('react.cabinet.dunning.table.days_over_due'),
    type: NUMBER_RANGE_TYPE,
    value: {},
    min: 0,
    max: 365,
  },
  {
    key: 'orderAmount',
    serverKey: {
      key: 'order_amount',
      from: 'order_amount_from',
      to: 'order_amount_to',
    },
    label: I18n.t('react.cabinet.dunning.table.order_amount'),
    type: NUMBER_RANGE_TYPE,
    value: {},
  },
  {
    key: 'payerType',
    serverKey: 'payer_type',
    label: I18n.t('react.cabinet.dunning.table.payer_type'),
    type: SELECT_TYPE,
    options: PAYER_TYPE_OPTIONS,
  },
  {
    key: 'dunningStatus',
    serverKey: 'dunning_status',
    label: I18n.t('react.cabinet.dunning.table.dunning_strategy_status'),
    type: SELECT_TYPE,
    options: DUNNING_STATUS_TYPE_OPTIONS,
  },
  {
    key: 'inkassoStatus',
    serverKey: 'inkasso_status',
    label: I18n.t('react.cabinet.dunning.table.inkasso_strategy_status'),
    type: SELECT_TYPE,
    options: INKASSO_STATUS_TYPE_OPTIONS_ADMIN,
  },
  {
    key: 'inkassoStatusSeller',
    serverKey: 'inkasso_status',
    label: I18n.t('react.cabinet.dunning.table.inkasso_strategy_status'),
    type: SELECT_TYPE,
    options: INKASSO_STATUS_TYPE_OPTIONS_SELLER,
  },
  {
    key: 'strategyStatus',
    serverKey: 'strategy_status',
    label: I18n.t('react.cabinet.dunning.table.strategy_status'),
    type: SELECT_TYPE,
    options: STRATEGY_STATUS_TYPE_OPTIONS,
  },
  {
    key: 'preferredStrategy',
    serverKey: 'preferred_strategy',
    label: 'Strategy type power seller',
    type: SELECT_TYPE,
    options: DUNNING_STRATEGY_TYPE_OPTIONS,
  },
  {
    key: 'strategyType',
    serverKey: 'strategy_type',
    label: I18n.t('react.cabinet.dunning.table.strategy_type'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: DUNNING_STRATEGY_TYPE_OPTIONS,
  },
  {
    key: 'outcomeStatus',
    serverKey: 'outcome_status',
    label: I18n.t('react.cabinet.dunning.status.outcome_status'),
    type: SELECT_TYPE,
    options: OUTCOME_TYPE_OPTIONS,
  },
  {
    key: 'resellerCompany',
    serverKey: 'reseller_company',
    label: 'Reseller entity name',
    type: SELECT_WITH_SEARCH_TYPE,
    store: 'resellersStore',
    skipInitialRequest: true,
    formatOptions: (list) =>
      list.map((r) => ({
        value: r.userProfile?.company,
        label: r.userProfile?.company,
      })),
  },
  {
    key: 'pricingPlanForm',
    serverKey: 'period_type',
    label: I18n.t('react.cabinet.common.pricing_plan'),
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: PERIOD_ORDER_TYPE_OPTIONS,
    value: [],
  },
  {
    key: 'dateResolved',
    serverKey: {
      key: 'date_resolved',
      from: 'resolved_from',
      till: 'resolved_till',
    },
    label: I18n.t('react.cabinet.dunning.table.date_resolved'),
    type: DATE_RANGE_TYPE,
    value: {},
  },
  {
    key: 'isPowerSeller',
    serverKey: 'ps',
    label: 'Power Seller',
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'hasInvoice',
    serverKey: 'invoice',
    label: I18n.t('react.cabinet.dunning.table.has_invoice'),
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'dateCreated',
    serverKey: {
      key: 'created',
      from: 'created_from',
      till: 'created_till',
      created_from_time: 'created_from_time',
      created_till_time: 'created_till_time',
    },
    label: I18n.t('react.cabinet.dunning.table.date_created'),
    type: DATE_RANGE_TYPE,
    value: {},
  },
  {
    key: 'singleOrderId',
    serverKey: 'order_id',
    label: I18n.t('react.cabinet.common.order_id'),
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'pastDueOrderId',
    serverKey: 'id',
    label: I18n.t('react.cabinet.dunning.shared.multi_order_id'),
    type: SELECT_WITH_MULTIPLE_SEARCH_TYPE,
    queryParamName: 'order_id',
    searchable: false,
    formatOptions: (list) =>
      list.map(({ orderId, id }) => ({
        value: id,
        label: orderId,
      })),
    store: 'pastDueOrdersFiltersStore',
  },
  {
    key: 'dunningOrderId',
    serverKey: 'id',
    label: I18n.t('react.cabinet.dunning.shared.multi_order_id'),
    type: SELECT_WITH_MULTIPLE_SEARCH_TYPE,
    searchable: false,
    formatOptions: (list) =>
      list.map(({ order: { orderId }, id }) => ({
        value: id,
        label: orderId,
      })),
    store: 'dunningFiltersStore',
  },
  {
    key: 'claimsOrderId',
    serverKey: 'id',
    label: I18n.t('react.cabinet.dunning.shared.multi_order_id'),
    type: SELECT_WITH_MULTIPLE_SEARCH_TYPE,
    searchable: false,
    formatOptions: (list) =>
      list.map(({ order: { orderId }, id }) => ({
        value: id,
        label: orderId,
      })),
    store: 'claimsFiltersStore',
  },
  {
    key: 'csvDownloaded',
    serverKey: 'csv_downloaded',
    label: 'CSV Downloaded',
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'numberInvoice',
    serverKey: 'invoice_number',
    label: I18n.t('react.cabinet.common.invoice_number'),
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'deletionState',
    serverKey: 'deletion_state',
    label: 'Deletion Status',
    type: SELECT_TYPE,
    options: DELETION_STATES_OPTIONS,
  },
  {
    key: 'accountDeactivatedAt',
    serverKey: {
      key: 'deactivated_at',
      from: 'deactivated_from_time',
      till: 'deactivated_till_time',
    },
    label: 'Deactivated',
    type: DATE_RANGE_TYPE,
    value: {},
  },
  {
    key: 'dueAmount',
    serverKey: {
      key: 'amount',
      from: 'amount_from',
      to: 'amount_to',
      placeholderFrom: I18n.t('shared.common.from'),
      placeholderTo: I18n.t('shared.common.till'),
    },
    label: I18n.t('react.cabinet.common.due_amount'),
    type: NUMBER_RANGE_TYPE,
    value: {},
  },
  {
    key: 'daysOverdue',
    serverKey: {
      key: 'days_overdue',
      from: 'days_overdue_from',
      to: 'days_overdue_to',
      placeholderFrom: I18n.t('shared.common.from'),
      placeholderTo: I18n.t('shared.common.till'),
    },
    label: I18n.t('react.cabinet.common.days_overdue'),
    type: NUMBER_RANGE_TYPE,
    value: {},
  },
  {
    key: 'buyerCountryCode',
    serverKey: 'buyer_country_code',
    label: I18n.t('react.cabinet.common.buyer_country'),
    type: SELECT_WITH_MULTIPLE_SEARCH_TYPE,
    store: 'countriesStore',
    searchLocally: true,
  },
  {
    key: 'fileOrigin',
    serverKey: 'file_origin',
    label: 'File origin',
    type: SELECT_TYPE,
    options: [
      {
        value: 'upload',
        label: 'Upload',
      },
      {
        value: 'download',
        label: 'Download',
      },
    ],
  },
  {
    key: 'writtenOffAt',
    serverKey: {
      key: 'written_off_at',
      from: 'written_off_from',
      till: 'written_off_till',
    },
    label: 'Written off',
    type: DATE_RANGE_WITHOUT_TIME_TYPE,
    value: {},
  },
  {
    key: 'writtenOf',
    serverKey: 'is_written_off',
    label: 'Written off',
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'paymentAuthorizationStatus',
    serverKey: 'status',
    label: 'Status',
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: PAYMENT_AUTHORIZATION_STATUSES,
  },
  {
    key: 'paymentAuthorizationProvider',
    serverKey: 'provider',
    label: 'Provider',
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: PAYMENT_AUTHORIZATION_PROVIDERS,
  },
  {
    key: 'paymentAuthorizationPaymentMethod',
    serverKey: 'payment_method',
    label: 'Payment method',
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: PAYMENT_AUTHORIZATION_PAYMENT_METHODS,
  },
  {
    key: 'issuedAt',
    serverKey: {
      key: 'issued_at',
      from: 'issued_from',
      till: 'issued_till',
    },
    label: I18n.t('react.cabinet.common.issued'),
    type: DATE_RANGE_WITHOUT_TIME_TYPE,
    value: {},
  },
  {
    key: 'gegenkontoAccount',
    serverKey: 'gen_account',
    label: 'Gegenkonto account',
    type: NUMBER_INPUT_TYPE,
  },
  {
    key: 'genAccountPresent',
    serverKey: 'gen_account_present',
    label: 'Gegenkonto account present',
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'documentField1Present',
    serverKey: 'w_document_field_1_present',
    label: 'Is Belegfeld 1 present',
    type: SELECT_TYPE,
    options: YES_NO_OPTIONS,
    value: YES_NO_OPTIONS[0].value,
  },
  {
    key: 'documentField1',
    serverKey: 'document_field_1',
    label: 'Belegfeld 1  value',
    type: TEXT_INPUT_TYPE,
  },
  {
    key: 'exportDate',
    serverKey: {
      key: 'export_date',
      from: 'export_date_from',
      till: 'export_date_till',
    },
    label: 'Export date',
    type: DATE_RANGE_TYPE,
    value: {},
  },
  {
    key: 'bookingType',
    serverKey: 'booking_type',
    label: 'Booking type',
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: BOOKING_TYPE_OPTIONS,
  },
  {
    key: 'datevState',
    serverKey: 'datev_state',
    label: 'Datev state',
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: DATEV_STATE_OPTIONS,
  },
  {
    key: 'datevLogState',
    serverKey: 'datev_export_status',
    label: 'Datev export status',
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: DATEV_STATE_OPTIONS,
  },
  {
    key: 'eventKey',
    serverKey: 'event_key',
    label: 'Event key',
    type: SELECT_TYPE_WITH_MULTIPLE,
    options: EVENT_KEY_OPTIONS,
  },

  {
    key: 'createdAt',
    serverKey: {
      key: 'created',
      from: 'from',
      till: 'till',
    },
    label: I18n.t('shared.common.created'),
    type: DATE_RANGE_TYPE,
    value: {},
  },
] // Leave createdAt always the last item

export const FILTERS_KEYS_LIST = flatArray(
  FILTERS_LIST.map((item) => {
    if (isObject(item.serverKey)) {
      return [...Object.values(item.serverKey)]
    }
    return item.serverKey
  })
)
/* eslint-enable camelcase */
